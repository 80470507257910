// Components
import { useState } from 'react';
import {
  Box,
  Button,
  Collapse,
  Container,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import Footer from '../components/footer';

// Utilities
import { IsDesktop } from '../utilities/mediaQuery';
import { history } from '../utilities/history';
import { auth } from '../utilities/firebaseController';
import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { notifs } from '../components/notifications';
import MediaQuery from 'react-responsive';

// Colors
import { green, grey } from '@mui/material/colors';
import { accent900, accent800, primary500 } from '../utilities/colors';

// Icons
import RegisterIcon from '@mui/icons-material/AppRegistration';
import InfoIcon from '@mui/icons-material/Info';
import BackIcon from '@mui/icons-material/ChevronLeft';

// Assets
import imageRegister from '../assets/mr-waster-phone.png';

export default function Register() {
  // STYLES
  // Generic
  var isDesktop = IsDesktop();
  // Specific
  const mainContainer = {
    px: isDesktop ? '15px' : 0,
    gap: '15px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  };

  // VARIABLES
  const errorCodes = [
    'Email is already in use.',
    'Invalid email.',
    'Registration using email and password are currently offline.',
    'Password is not strong enough.',
    'Internal error. Contact us to correct this.',
    'Account not found.',
  ];
  const [user, loading, error] = useAuthState(auth);

  const [registerEmail, setRegisterEmail] = useState(null);
  const [registerPassword, setRegisterPassword] = useState(null);
  const [registerRepeatPassword, setRegisterRepeatPassword] = useState(null);

  const [registrationError, setRegistrationError] = useState(false);
  const [registerEmailError, setRegisterEmailError] = useState(false);
  const [registerPasswordError, setRegisterPasswordError] = useState(false);
  const [registerRepeatPasswordError, setRegisterRepeatPasswordError] =
    useState(false);

  const [registrationErrorMessage, setRegistrationErrorMessage] =
    useState(null);
  const [registerEmailErrorMessage, setRegisterEmailErrorMessage] =
    useState(null);
  const [registerPasswordErrorMessage, setRegisterPasswordErrorMessage] =
    useState(null);
  const [
    registerRepeatPasswordErrorMessage,
    setRegisterRepeatPasswordErrorMessage,
  ] = useState(null);

  const registerEmailChange = (e) => {
    let registerEmailTemp = e.target.value;
    let registerEmailAt0 = e.target.value.split('@')[0];
    let registerEmailAt1 = e.target.value.split('@')[1];
    if (registerEmailTemp.length < 1) {
      setRegisterEmailError(true);
      setRegisterEmailErrorMessage('Please enter your email');
    } else if (
      !registerEmailAt0 ||
      !registerEmailAt1 ||
      !registerEmailAt1.split('.')[0] ||
      !registerEmailAt1.split('.')[1]
    ) {
      setRegisterEmailError(true);
      setRegisterEmailErrorMessage('Please enter a valid email');
    } else {
      setRegisterEmailError(false);
      setRegisterEmailErrorMessage(null);
    }
    setRegisterEmail(registerEmailTemp);
  };
  const registerPasswordChange = (e) => {
    let registerPasswordTemp = e.target.value;
    if (registerPasswordTemp.length < 1) {
      setRegisterPasswordError(true);
      setRegisterPasswordErrorMessage('Please enter your password');
    } else if (registerPasswordTemp.length < 6) {
      setRegisterPasswordError(true);
      setRegisterPasswordErrorMessage('Password cannot be below 6 characters');
    } else {
      setRegisterPasswordError(false);
      setRegisterPasswordErrorMessage(null);
    }
    setRegisterPassword(registerPasswordTemp);
  };
  const registerRepeatPasswordChange = (e) => {
    let registerRepeatPasswordTemp = e.target.value;
    if (registerRepeatPasswordTemp.length < 1) {
      setRegisterRepeatPasswordError(true);
      setRegisterRepeatPasswordErrorMessage('Please re-enter your password');
    } else if (registerRepeatPasswordTemp.length < 6) {
      setRegisterRepeatPasswordError(true);
      setRegisterRepeatPasswordErrorMessage(
        'Password cannot be below 6 characters'
      );
    } else if (registerRepeatPasswordTemp !== registerPassword) {
      setRegisterRepeatPasswordError(true);
      setRegisterRepeatPasswordErrorMessage('Passwords do not match');
    } else {
      setRegisterRepeatPasswordError(false);
      setRegisterRepeatPasswordErrorMessage(null);
    }
    setRegisterRepeatPassword(registerRepeatPasswordTemp);
  };
  const [resetPasswordPaneState, setResetPasswordPaneState] = useState(false);
  const [resetPasswordEmail, setResetPasswordEmail] = useState('');
  const [resetPasswordEmailError, setResetPasswordEmailError] = useState(false);
  const [resetPasswordEmailErrorMessage, setResetPasswordEmailErrorMessage] =
    useState('');
  const resetPasswordEmailChange = (e) => {
    let resetPasswordEmailTemp = e.target.value;
    let resetPasswordEmailAt0 = e.target.value.split('@')[0];
    let resetPasswordEmailAt1 = e.target.value.split('@')[1];
    if (resetPasswordEmailTemp.length < 1) {
      setResetPasswordEmailError(true);
      setResetPasswordEmailErrorMessage('Please enter your email');
    } else if (
      !resetPasswordEmailAt0 ||
      !resetPasswordEmailAt1 ||
      !resetPasswordEmailAt1.split('.')[0] ||
      !resetPasswordEmailAt1.split('.')[1]
    ) {
      setResetPasswordEmailError(true);
      setResetPasswordEmailErrorMessage('Please enter a valid email');
    } else {
      setResetPasswordEmailError(false);
      setResetPasswordEmailErrorMessage(null);
    }
    setResetPasswordEmail(resetPasswordEmailTemp);
  };

  //  FUNCTIONS
  const register = (e) => {
    e.preventDefault();

    createUserWithEmailAndPassword(auth, registerEmail, registerPassword)
      .then(() => {
        history.push('/dashboard');
        history.go(0);
      })
      .catch((err) => {
        setRegistrationError(true);
        if (err.code === 'auth/email-already-in-use') {
          setRegistrationErrorMessage(errorCodes[0]);
        } else if (err.code === 'auth/invalid-email') {
          setRegistrationErrorMessage(errorCodes[1]);
        } else if (err.code === 'auth/operation-not-allowed') {
          setRegistrationErrorMessage(errorCodes[2]);
        } else if (err.code === 'auth/weak-password') {
          setRegistrationErrorMessage(errorCodes[3]);
        } else {
          setRegistrationErrorMessage(
            'Something went wrong. Please contact us to resolve this issue.'
          );
        }
        console.log(err.code);
        console.log(err.message);
      });
  };
  const resetPassword = (e) => {
    e.preventDefault();
    if (resetPasswordEmailError) return;
    sendPasswordResetEmail(auth, resetPasswordEmail)
      .then(() => {
        notifs.push({
          type: 2,
          background: green[700],
          content: (
            <Box>
              <Typography
                variant="body2"
                sx={{ '&>span': { fontWeight: 500 } }}
              >
                Reset password email sent to <span>{resetPasswordEmail}</span>.
              </Typography>
              <Typography variant="caption">
                Please check your spam/junk folder.
              </Typography>
            </Box>
          ),
        });
      })
      .catch((err) => {
        setResetPasswordEmailError(true);
        if (err.code === 'auth/invalid-email') {
          setResetPasswordEmailErrorMessage(errorCodes[1]);
        } else if (err.code === 'auth/user-not-found') {
          setResetPasswordEmailErrorMessage(errorCodes[5]);
        } else if (
          err.code === 'auth/missing-android-pkg-name' ||
          err.code === 'auth/missing-continue-uri' ||
          err.code === 'auth/missing-ios-bundle-id' ||
          err.code === 'auth/invalid-continue-uri' ||
          err.code === 'auth/unauthorized-continue-uri'
        ) {
          setResetPasswordEmailErrorMessage(errorCodes[4]);
        } else {
          setResetPasswordEmailErrorMessage(
            'Something went wrong. Please contact us to resolve this issue.'
          );
        }
        console.log(err.code);
        console.log(err.message);
      });
  };

  return (
    <Box sx={mainContainer}>
      <Box
        sx={{
          background: grey[300],
          borderBottomLeftRadius: '30px',
          borderBottomRightRadius: '30px',
          boxShadow: `0 1px 2px ${grey[900]}`,
        }}
      >
        <Container
          sx={{
            p: '15px',
            background: grey[100],
            borderBottomLeftRadius: '30px',
            borderBottomRightRadius: '30px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <RegisterIcon sx={{ fontSize: '3rem', color: accent900 }} />
            <Box sx={{ ml: '15px' }}>
              <Typography variant="h5" sx={{ color: accent900 }}>
                Register
              </Typography>
              <Typography variant="caption" sx={{ color: grey[700] }}>
                Register if you don't have an account yet.
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          p: isDesktop ? '15px' : 0,
          background: grey[300],
          borderRadius: '5px',
          flexGrow: 1,
          display: 'flex',
        }}
      >
        <Container
          sx={{
            p: '15px',
            background: grey[200],
            borderRadius: '5px',
            gap: '15px',
            flexGrow: 1,
            display: 'flex',
          }}
        >
          <MediaQuery minWidth={480}>
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src={imageRegister}
                style={{ maxWidth: '350px', minWidth: '250px' }}
                alt="Mr. Waster Greeting"
              />
            </Box>
          </MediaQuery>
          <Box
            sx={{
              p: isDesktop ? '30px' : '15px',
              background: grey[100],
              borderRadius: '5px',
              boxShadow: `0 1px 2px ${grey[900]}`,
              gap: '30px',
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {user && (
              <Box
                sx={{
                  minHeight: '35vh',
                  gap: '30px',
                  flexGrow: 1,
                  borderBottomLeftRadius: '5px',
                  borderBottomRightRadius: '5px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Box sx={{ display: 'flex' }}>
                    <Typography noWrap variant="h5">
                      Register to&nbsp;
                    </Typography>
                    <Typography noWrap variant="h5" sx={{ color: primary500 }}>
                      Waster
                    </Typography>
                    <Typography
                      noWrap
                      variant="h5"
                      sx={{ color: accent800, fontWeight: 500 }}
                    >
                      APP
                    </Typography>
                  </Box>
                  <Typography
                    variant="caption"
                    sx={{ '&> span': { fontWeight: 500 } }}
                  >
                    Register to see your services and schedules.
                  </Typography>
                </Box>
                <Box
                  sx={{
                    flexGrow: 1,
                    gap: '15px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      '&>span': {
                        fontWeight: '500',
                        color: accent800,
                      },
                    }}
                  >
                    You are logged in as <span>{user.email}</span>.
                  </Typography>
                  <Button
                    onClick={() => {
                      history.push('/home');
                      history.go(0);
                    }}
                    startIcon={<BackIcon />}
                    sx={{
                      background: accent800,
                      color: grey[50],
                      '&:hover': {
                        background: accent900,
                      },
                    }}
                  >
                    Go back
                  </Button>
                </Box>
              </Box>
            )}
            {!user && (
              <Box
                component="form"
                onSubmit={register}
                sx={{
                  minHeight: '35vh',
                  gap: '30px',
                  flexGrow: 1,
                  borderBottomLeftRadius: '5px',
                  borderBottomRightRadius: '5px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                }}
              >
                <Box>
                  <Box sx={{ display: 'flex' }}>
                    <Typography noWrap variant="h5">
                      Register to&nbsp;
                    </Typography>
                    <Typography noWrap variant="h5" sx={{ color: primary500 }}>
                      Waster
                    </Typography>
                    <Typography
                      noWrap
                      variant="h5"
                      sx={{ color: accent800, fontWeight: 500 }}
                    >
                      APP
                    </Typography>
                  </Box>
                  <Typography
                    variant="caption"
                    sx={{ '&> span': { fontWeight: 500 } }}
                  >
                    Register to see your services and schedules.
                  </Typography>
                </Box>
                <Collapse in={registrationError}>
                  <Box
                    sx={{
                      p: '15px',
                      background: accent800,
                      color: grey[50],
                      borderRadius: '5px',
                    }}
                  >
                    <Typography>{registrationErrorMessage}</Typography>
                  </Box>
                </Collapse>
                <Box
                  sx={{
                    gap: '15px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <TextField
                    required
                    fullWidth
                    type="email"
                    label="Email Address"
                    value={registerEmail}
                    onChange={registerEmailChange}
                    error={registerEmailError}
                    helperText={
                      registerEmailError ? registerEmailErrorMessage : null
                    }
                  />
                  <TextField
                    required
                    fullWidth
                    type="password"
                    label="Password"
                    value={registerPassword}
                    onChange={registerPasswordChange}
                    error={registerPasswordError}
                    helperText={
                      registerPasswordError
                        ? registerPasswordErrorMessage
                        : null
                    }
                  />
                  <TextField
                    required
                    fullWidth
                    type="password"
                    label="Repeat Password"
                    value={registerRepeatPassword}
                    onChange={registerRepeatPasswordChange}
                    error={registerRepeatPasswordError}
                    helperText={
                      registerRepeatPasswordError
                        ? registerRepeatPasswordErrorMessage
                        : null
                    }
                  />
                  <Button
                    type="submit"
                    sx={{
                      background: accent800,
                      color: grey[50],
                      '&:hover': {
                        background: accent900,
                      },
                    }}
                  >
                    Register
                  </Button>
                </Box>
              </Box>
            )}
            {!user && (
              <Collapse in={resetPasswordPaneState}>
                <Box
                  component="form"
                  onSubmit={resetPassword}
                  sx={{
                    p: '15px',
                    background: grey[50],
                    borderRadius: '5px',
                    boxShadow: `0 1px 2px ${grey[900]}`,
                    gap: '15px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box>
                    <Typography sx={{ fontWeight: 500 }}>
                      Reset password
                    </Typography>
                    <Typography variant="caption">
                      We will send you an email with the instructions on how to
                      reset your password.
                    </Typography>
                  </Box>
                  <TextField
                    required
                    fullWidth
                    type="email"
                    label="Email Address"
                    value={resetPasswordEmail}
                    onChange={resetPasswordEmailChange}
                    error={resetPasswordEmailError}
                    helperText={
                      resetPasswordEmailError
                        ? resetPasswordEmailErrorMessage
                        : null
                    }
                  />
                  <Box
                    sx={{
                      gap: '15px',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button
                      onClick={() => {
                        setResetPasswordPaneState(false);
                      }}
                      sx={{
                        background: grey[50],
                        color: accent800,
                        '&:hover': {
                          color: accent900,
                        },
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      sx={{
                        background: accent800,
                        color: grey[50],
                        '&:hover': {
                          background: accent900,
                        },
                      }}
                    >
                      Send email
                    </Button>
                  </Box>
                </Box>
              </Collapse>
            )}
            {!user && (
              <Box
                sx={{
                  p: '15px',
                  gap: '15px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography>
                  Forgot Password?&nbsp;
                  <Link
                    onClick={() => {
                      setResetPasswordPaneState(!resetPasswordPaneState);
                    }}
                    sx={{
                      fontWeight: 500,
                      textDecoration: 'none',
                      cursor: 'pointer',
                    }}
                  >
                    {resetPasswordPaneState ? 'Cancel' : 'Reset my password'}
                  </Link>
                </Typography>
                <Typography>
                  Already registered?&nbsp;
                  <Link
                    onClick={() => {
                      history.push('login');
                      history.go(0);
                    }}
                    sx={{
                      fontWeight: 500,
                      textDecoration: 'none',
                      cursor: 'pointer',
                    }}
                  >
                    Log in instead
                  </Link>
                </Typography>
              </Box>
            )}
            {!user && (
              <Box sx={{ p: '15px', display: 'flex' }}>
                <InfoIcon sx={{ color: primary500 }} />
                <Box sx={{ ml: '15px' }}>
                  <Typography sx={{ fontWeight: 500 }}>
                    Guide to access your account
                  </Typography>
                  <Typography variant="body2">
                    You can log in or register using your email addresses that
                    you used to register your Waster services. If you do not
                    have access to these email addresses, please&nbsp;
                    <Link
                      onClick={() => {
                        history.push('contact');
                        history.go(0);
                      }}
                      sx={{
                        fontWeight: 500,
                        textDecoration: 'none',
                        cursor: 'pointer',
                      }}
                    >
                      contact us
                    </Link>
                    .
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Container>
      </Box>
      {isDesktop ? <Footer /> : null}
    </Box>
  );
}
