// Components
import { Box, Button, Container, Typography } from '@mui/material';
// Utilities
import { actionCodeSettings, auth } from '../utilities/firebaseController';
import { sendEmailVerification } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { notifs } from './notifications';

// Colors
import { green, grey, red } from '@mui/material/colors';
import { accent900, accent800 } from '../utilities/colors';

export default function Unverified() {
  //VARIABLES
  const [user, loading, error] = useAuthState(auth);

  // FUNCTIONS
  function sendVerification(user) {
    sendEmailVerification(user, actionCodeSettings)
      .then(() => {
        notifs.push({
          type: 2,
          background: green[700],
          content: (
            <Box>
              <Typography
                variant="body2"
                sx={{ '&>span': { fontWeight: 500 } }}
              >
                Verification email sent to <span>{user.email}</span>.
              </Typography>
              <Typography variant="caption">
                Please check your spam/junk folder.
              </Typography>
            </Box>
          ),
        });
      })
      .catch((err) => {
        console.log(err.code);
        if (err.code === 'auth/too-many-requests') {
          notifs.push({
            type: 3,
            background: red[700],
            content: (
              <Box>
                <Typography
                  variant="body2"
                  sx={{ '&>span': { fontWeight: 500 } }}
                >
                  Verification email sent to <span>{user.email}</span>.
                </Typography>
                <Typography variant="caption">
                  Please wait a little while before trying again.
                  <br />
                  Please check your spam/junk folder.
                </Typography>
              </Box>
            ),
          });
        }
      });
  }
  return (
    <Box
      sx={{
        p: '15px',
        flexGrow: 1,
        background: grey[100],
        borderRadius: '5px',
        boxShadow: `0 1px 2px ${grey[900]}`,
        display: 'flex',
      }}
    >
      <Container
        sx={{
          gap: '15px',
          minHeight: '50vh',
          flexGrow: 1,
          background: grey[50],
          borderRadius: '5px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" sx={{ color: grey[600] }}>
            Unverified Account
          </Typography>
          <Typography variant="body2" sx={{ color: grey[500] }}>
            Please verify your account first for your security.
          </Typography>
        </Box>
        <Button
          onClick={() => {
            sendVerification(user);
          }}
          sx={{
            color: accent800,
            '&:hover': {
              color: accent900,
            },
          }}
        >
          Send verification email
        </Button>
      </Container>
    </Box>
  );
}
