// Components
import { Box, Divider, Grid, Typography } from '@mui/material';

// Colors
import { grey } from '@mui/material/colors';

// Utilities
import { IsDesktop } from '../utilities/mediaQuery';

// Assets
import ausCert from '../assets/aus-cert.png';

// Icons
import ContactMap from '@mui/icons-material/Map';
import ContactPhone from '@mui/icons-material/Phone';
import ContactEmail from '@mui/icons-material/Email';
import ContactTime from '@mui/icons-material/AccessTimeFilled';
import MediaQuery from 'react-responsive';
import { primary500 } from '../utilities/colors';

export default function Footer() {
  // STYLES
  // Generic
  const ml15 = { ml: '15px' };
  const colorMid = { color: grey[500] };
  const flex = { display: 'flex' };
  const column = { flexDirection: 'column' };
  const img256max = { width: '100%', maxWidth: '256px' };
  const colorShiftPrimary = {
    transition: 'all 0.15s ease',
    cursor: 'pointer',
    '&:hover': {
      color: primary500,
    },
  };
  const dividerMid = { mt: '15px', borderBottomColor: grey[500] };
  const dividerMidVertical = { mx: '5px', borderColor: grey[500] };
  const textCenter = { textAlign: 'center' };

  // Specific
  const mainContainer = {
    px: '15px',
    py: '30px',
    background: grey[900],
    color: grey[50],
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
  };
  const gridContainer = {
    '&> .MuiGrid-item': {
      px: '15px',
    },
    gap: IsDesktop() ? 0 : '30px',
  };
  const gridItemSpacer = {
    gap: '15px',
    display: 'flex',
    flexDirection: 'column',
  };
  const ausCertContainer = {
    p: '15px',
    display: 'flex',
    justifyContent: 'center',
  };
  const aboutContainer = {
    mt: '30px',
    px: '15px',
    display: 'flex',
    flexDirection: IsDesktop() ? 'row' : 'column-reverse',
    justifyContent: 'space-between',
  };
  const documentContainer = {
    my: IsDesktop() ? 0 : '30px',
    display: 'flex',
    flexDirection: IsDesktop() ? 'row' : 'column',
  };

  // VARIABLES
  const servicesAus = [
    {
      label: 'Waste Management Sydney',
      url: 'https://waster.com.au/waste-management-sydney/',
    },
    {
      label: 'Waste Management Melbourne',
      url: 'https://waster.com.au/waste-management-melbourne/',
    },
    {
      label: 'Waste Management Brisbane',
      url: 'https://waster.com.au/waste-management-brisbane/',
    },
    {
      label: 'Waste Management Perth',
      url: 'https://waster.com.au/waste-management-perth-wa/',
    },
    {
      label: 'Waste Management Adelaide',
      url: 'https://waster.com.au/waste-management-adelaide/',
    },
    {
      label: 'Waste Management Gold Coast',
      url: 'https://waster.com.au/waste-management-gold-coast/',
    },
    {
      label: 'Waste Management Newcastle',
      url: 'https://waster.com.au/waste-management-newcastle-nsw/',
    },
    {
      label: 'Waste Management Wollongong',
      url: 'https://waster.com.au/wollongong-waste-disposal',
    },
    {
      label: 'Waste Removal Darwin',
      url: 'https://waster.com.au/waste-removal-darwin/',
    },
    {
      label: 'Waste Management Canberra',
      url: 'https://waster.com.au/garbage-collection-canberra/',
    },
  ];
  const contacts = [
    {
      icon: <ContactMap />,
      label: 'Level 8, 65 York St., Sydney 2000',
      tag: '',
    },
    {
      url: 'tel:1300927837',
      icon: <ContactPhone />,
      label: '1300 WASTER (1300 927 837)',
      tag: '',
    },
    {
      url: 'mailto:info@waster.com.au',
      icon: <ContactEmail />,
      label: 'info@waster.com.au',
      tag: '(Existing customers)',
    },
    {
      url: 'mailto:enquiries@waster.com.au',
      icon: <ContactEmail />,
      label: 'enquiries@waster.com.au',
      tag: '(Quotes)',
    },
    {
      icon: <ContactTime />,
      label: '9:00AM-5:00PM Mon-Fri (AEST)',
      tag: '',
    },
  ];
  const servicesWaste = [
    {
      url: 'https://waster.com.au/product-category/cardboard-paper-recycling/',
      icon: '📰',
      label: 'Cardboard & Paper Recycling',
    },
    {
      url: 'https://waster.com.au/product-category/commingle-recycle-service/',
      icon: '🍾',
      label: 'Commingle Recycle Service',
    },
    {
      url: 'https://waster.com.au/product-category/confidential-paper-destruction/',
      icon: '📜',
      label: 'Confidential Paper Destruction',
    },
    {
      url: 'https://waster.com.au/product-category/general-waste-bins/',
      icon: '🚮',
      label: 'General Waste Bins',
    },
    {
      url: 'https://waster.com.au/product-category/grease-trap-liquid-service/',
      icon: '🛢',
      label: 'Grease Trap Liquid Service',
    },
    {
      url: 'https://waster.com.au/product-category/medical-waste-service/',
      icon: '💉',
      label: 'Medical Waste Service',
    },
    {
      url: 'https://waster.com.au/product-category/organic-food-waste-service/',
      icon: '🍎',
      label: 'Organic Food Waste Service',
    },
    {
      url: 'https://waster.com.au/product-category/sanitary-bins-feminine-hygiene/',
      icon: '🚻',
      label: 'Sanitary Bins Services and Nappy Bins',
    },
    {
      url: 'https://waster.com.au/product-category/terracycle-recycling-boxes/',
      icon: '♻',
      label: 'Terracycle Recycling Boxes',
    },
  ];
  const documents = [
    {
      url: 'https://waster.com.au/',
      label: 'Waste Management Australia',
    },
    {
      url: 'https://waster.com.au/waste-recycling-privacy/',
      label: 'Privacy Policy',
    },
    {
      url: 'https://waster.com.au/sitemap/',
      label: 'Sitemap',
    },
  ];

  return (
    <Box sx={mainContainer}>
      <Grid container sx={gridContainer}>
        <Grid item xs={IsDesktop() ? 3 : 12} sx={gridItemSpacer}>
          <Typography variant="h6">About us</Typography>
          <Box>
            <Typography noWrap>⭐⭐⭐⭐⭐</Typography>
            <Typography noWrap>Excellent</Typography>
          </Box>
          <Typography variant="caption">
            Waster.com.au provides low cost and reliable waste, recycling and
            grease trap cleaning services to small and medium Australian
            businesses. Through our flexible 30 day agreements and high quality
            customer service – you can say goodbye to lock in contracts and
            hidden fees.
          </Typography>
          <Box sx={ausCertContainer}>
            <img src={ausCert} alt="Austalia Certification" style={img256max} />
          </Box>
        </Grid>
        <Grid item xs={IsDesktop() ? 3 : 12} sx={gridItemSpacer}>
          <Typography variant="h6">Australia-Wide Services</Typography>
          {servicesAus.map((service, index) => (
            <Box
              key={index}
              onClick={() => {
                window.open(service.url);
              }}
              sx={colorShiftPrimary}
            >
              <Typography variant="caption">{service.label}</Typography>
              {index < servicesAus.length - 1 ? (
                <Divider sx={dividerMid} />
              ) : null}
            </Box>
          ))}
        </Grid>

        <Grid item xs={IsDesktop() ? 3 : 12} sx={gridItemSpacer}>
          <Typography variant="h6">Contact us</Typography>
          {contacts.map((contact, index) => (
            <Box>
              <Box
                key={index}
                onClick={() => {
                  if (contact.url) {
                    window.open(contact.url);
                  }
                }}
                sx={{ ...flex, ...colorShiftPrimary }}
              >
                {contact.icon}
                <Box sx={{ ...ml15, ...flex, ...column }}>
                  <Typography variant="caption">{contact.label}</Typography>
                  <Typography variant="caption">{contact.tag}</Typography>
                </Box>
              </Box>
              {index < contacts.length - 1 ? <Divider sx={dividerMid} /> : null}
            </Box>
          ))}
        </Grid>
        <Grid item xs={IsDesktop() ? 3 : 12} sx={gridItemSpacer}>
          <Typography variant="h6">Waste & Recycling Services</Typography>
          {servicesWaste.map((service, index) => (
            <Box>
              <Box
                key={index}
                onClick={() => {
                  window.open(service.url);
                }}
                sx={{ ...flex, ...colorShiftPrimary }}
              >
                {service.icon}
                <Typography variant="caption" sx={ml15}>
                  {service.label}
                </Typography>
              </Box>
              {index < servicesWaste.length - 1 ? (
                <Divider sx={dividerMid} />
              ) : null}
            </Box>
          ))}
        </Grid>
      </Grid>
      <Box sx={aboutContainer}>
        <Typography variant="caption" sx={colorMid}>
          2017 © Copyright Waster Pty Ltd, All Rights Reserved.
        </Typography>
        <Box sx={documentContainer}>
          {documents.map((document, index) => (
            <Box
              sx={flex}
              key={index}
              onClick={() => {
                window.open(document.url);
              }}
            >
              <Typography sx={{ ...colorShiftPrimary, ...textCenter }}>
                {document.label}
              </Typography>
              {index < documents.length - 1 ? (
                <MediaQuery minWidth={480}>
                  <Divider orientation="vertical" sx={dividerMidVertical} />
                </MediaQuery>
              ) : null}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
