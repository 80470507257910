// Components
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Container,
  Divider,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Footer from '../components/footer';
import { useDropzone } from 'react-dropzone';

// Utilities
import { IsDesktop } from '../utilities/mediaQuery';
import axios from 'axios';
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';
import { auth, storage } from '../utilities/firebaseController';
import { useAuthState } from 'react-firebase-hooks/auth';

// Colors
import { green, grey, lightBlue } from '@mui/material/colors';
import {
  accent900,
  accent800,
  primary500,
  primary900,
  secondary500,
  secondary900,
} from '../utilities/colors';

// Icons
import AdminIcon from '@mui/icons-material/AdminPanelSettings';
import DocumentIcon from '@mui/icons-material/Article';
import MoreIcon from '@mui/icons-material/ExpandMore';
import LessIcon from '@mui/icons-material/ExpandLess';
import CloseIcon from '@mui/icons-material/Close';
import UploadIcon from '@mui/icons-material/Upload';
import CopyIcon from '@mui/icons-material/ContentCopy';
import SaveIcon from '@mui/icons-material/Save';
import DocumentAttachmentIcon from '@mui/icons-material/FilePresent';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { history } from '../utilities/history';
import * as React from 'react';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Stack from '@mui/joy/Stack';
import Autocomplete from '@mui/joy/Autocomplete';

// Component Functions
function Step(props) {
  const spacing = props.spacing ? props.spacing : 15;
  const number = props.number;
  const description = props.description
    ? props.description
    : 'Please complete the requirements below';

  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <Box
        sx={{
          mt: `${spacing / 3}px`,
          p: `${spacing / 3}px`,
          width: '4ch',
          height: '4ch',
          borderStyle: 'solid',
          borderColor: primary500,
          borderWidth: '2px',
          borderRadius: '50%',
          flexShrink: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          boxSizing: 'border-box',
        }}
      >
        <Typography
          variant="body2"
          sx={{
            textAlign: 'center',
            fontWeight: 500,
          }}
        >
          {number}
        </Typography>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        <Box
          sx={{ pb: `${spacing / 3}px`, pl: `${spacing / 3}px`, height: '2ch' }}
        >
          {number && (
            <Typography variant="body2" sx={{ fontWeight: 500 }}>
              Step {number}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            pt: `${spacing / 3}px`,
            pl: `${spacing / 3}px`,
            borderTopStyle: 'solid',
            borderColor: primary500,
            borderWidth: '2px',
          }}
        >
          <Typography variant="caption">{description}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default function Admin() {
  // STYLES
  // Generic
  var isDesktop = IsDesktop();
  const spacing = 15;
  const radius = 5;
  // Specific
  const mainContainer = {
    px: isDesktop ? `${spacing}px` : 0,
    flexGrow: 1,
    gap: `${spacing}px`,
    display: 'flex',
  };

  // VARIABLES
  const [user, loading, error] = useAuthState(auth);
  const [isAdmin, setIsAdmin] = useState(null);
  // Post
  const [attachDocPaneState, setAttachDocPaneState] = useState(true);
  const [postName, setPostName] = useState('');
  const [postLink, setPostLink] = useState('');
  const [postAccountID, setPostAccountID] = useState('');
  const postAccountIDChange = (e) => {
    setPostAccountID(e.target.value);
    let accountIDTemp = e.target.value;
    if (accountIDTemp.length === 0) {
      setPostAccountIDErrorText('Please provide an account ID');
      setPostAccountIDError(true);
    } else {
      setPostAccountIDError(false);
    }
  };
  const [postAccountIDError, setPostAccountIDError] = useState(false);
  const [postAccountIDErrorText, setPostAccountIDErrorText] = useState(
    'Please check fields'
  );
  const [posting, setPosting] = useState(false);
  const [posted, setPosted] = useState(false);
  const [postProgress, setPostProgress] = useState(0);
  const [postFile, setPostFile] = useState(null);
  const postFileChange = (e) => {
    setPostFile(e[0]);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'application/pdf',
    onDrop: (file) => {
      postFileChange(file);
    },
  });
  const [postFileLinkCopied, setPostFileLinkCopied] = useState(false);
  // Existing
  const [existingDocPaneState, setExistingDocPaneState] = useState(true);
  const [existingDocs, setExistingDocs] = useState(null);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [selectedDocName, setSelectedDocName] = useState('');
  const [selectedDocAccountID, setSelectedDocAccountID] = useState(0);
  const [selectedDocLink, setSelectedDocLink] = useState('');
  const [hasChanges, setHasChanges] = useState(false);
  const [putting, setPutting] = useState(false);
  const selectedDocNameChange = (e) => {
    setSelectedDocName(e.target.value);
    setHasChanges(true);
  };
  const selectedDocAccountIDChange = (e) => {
    setSelectedDocAccountID(e.target.value);
    setHasChanges(true);
  };
  const [selectedDocLinkCopied, setSelectedDocLinkCopied] = useState(false);

  // Search
  // const [openExistingDocs, setOpenExistingDocs] = React.useState(false);
  // const [options, setOptions] = React.useState([]);
  // const loadingExistingDocs = openExistingDocs && options.length === 0;
  // const [selectedExistingDoc, setSelectedExistingDoc] = useState('');

  // function searchHandler(e) {
  //   setSelectedExistingDoc(e.target.value);
  //   console.log(selectedExistingDoc);
  // }

  // React.useEffect(() => {
  //   let active = existingDocs !== null;

  //   if (!loadingExistingDocs) {
  //     return undefined;
  //   }

  //   if (active) {
  //     setOptions([...existingDocs]);
  //   }

  //   return () => {
  //     active = false;
  //   };
  // }, [loadingExistingDocs]);

  // React.useEffect(() => {
  //   if (!openExistingDocs) {
  //     setOptions([]);
  //   }
  // }, [openExistingDocs]);

  // FUNCTIONS
  function resetPostItems() {
    setPostName('');
    setPostAccountID('');
    setPostLink('');
    setPostFile(null);
    setPosted(false);
  }
  function getRADoc() {
    setExistingDocs(null);
    const urlGetRADoc =
      'https://us-central1-wasterapp-9ea2a.cloudfunctions.net/riskAssessmentDoc/';
    axios
      .get(urlGetRADoc)
      .then((response) => {
        setExistingDocs(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function uploadDoc(doc) {
    if (!doc) return;

    const storageRef = ref(storage, `/RADoc/${doc.name}`);
    const uploadTask = uploadBytesResumable(storageRef, doc);
    setPosting(true);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setPostProgress(prog);
      },
      (error) => {
        console.log(error);
      },
      () => {
        setPosting(false);
        setPostName(uploadTask.snapshot.ref.name);
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setPostLink(url);
        });
        setPosted(true);
      }
    );
  }
  function postRADoc() {
    if (
      postAccountID.length < 1 ||
      postName.length < 1 ||
      postLink.length < 1
    ) {
      // setPostError(true);
      // setPostErrorText('Please check fields');
      return;
    }
    // setPostNotifLoading(true);
    const urlPostRADoc =
      'https://us-central1-wasterapp-9ea2a.cloudfunctions.net/riskAssessmentDoc';
    axios
      .post(urlPostRADoc, {
        name: postName,
        accountID: postAccountID,
        link: postLink,
      })
      .then(() => {
        resetPostItems();
        getRADoc();
      })
      .catch((error) => {
        console.log(error);
        getRADoc();
      });
  }
  function resetChanges() {
    if (selectedDoc) {
      setSelectedDocName(selectedDoc.name);
      setSelectedDocAccountID(selectedDoc.accountID);
      setSelectedDocLink(selectedDoc.link);
    }
  }
  function putRADoc() {
    if (!selectedDoc) return;
    const urlPutRADoc = `https://us-central1-wasterapp-9ea2a.cloudfunctions.net/riskAssessmentDoc/${selectedDoc.id}`;
    axios
      .put(urlPutRADoc, {
        name: selectedDocName,
        accountID: selectedDocAccountID,
        link: selectedDocLink,
      })
      .then(() => {
        getRADoc();
        setSelectedDoc(null);
      })
      .catch((error) => {
        console.log(error);
        getRADoc();
      });
  }
  function delRADoc() {
    if (!selectedDoc) return;
    const urlDelRADoc = `https://us-central1-wasterapp-9ea2a.cloudfunctions.net/riskAssessmentDoc/${selectedDoc.id}`;
    axios
      .delete(urlDelRADoc)
      .then(() => {
        getRADoc();
        setSelectedDoc(null);
      })
      .catch((error) => {
        console.log(error);
        getRADoc();
      });
  }
  function getIsAdmin(email) {
    const urlIsAdmin = `https://us-central1-wasterapp-9ea2a.cloudfunctions.net/wastedge/isAdmin/${email}`;
    axios
      .get(urlIsAdmin)
      .then((response) => {
        setIsAdmin(response.data.isAdmin);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // EFFECTS
  useEffect(() => {
    if (user) {
      getIsAdmin(user.email);
    } else if (!user && !loading && !error) {
      setIsAdmin(false);
    }
  }, [user, loading, error]);
  useEffect(() => {
    if (isAdmin === false) {
      history.push('404');
      history.go(0);
    }
  }, [isAdmin]);
  useEffect(() => {
    getRADoc();
  }, []);
  useEffect(() => {
    if (selectedDoc) {
      setSelectedDocName(selectedDoc.name);
      setSelectedDocAccountID(selectedDoc.accountID);
      setSelectedDocLink(selectedDoc.link);
    } else {
      setSelectedDocName('');
      setSelectedDocAccountID(0);
      setSelectedDocLink('');
      setHasChanges(false);
    }
  }, [selectedDoc]);
  useEffect(() => {
    if (selectedDocLinkCopied) {
      setTimeout(() => {
        setSelectedDocLinkCopied(false);
      }, 3000);
    }
    if (postFileLinkCopied) {
      setTimeout(() => {
        setPostFileLinkCopied(false);
      }, 3000);
    }
  }, [selectedDocLinkCopied, postFileLinkCopied]);

  return (
    <Box sx={mainContainer}>
      {user && (
        <Box
          sx={{
            flexGrow: 1,
            gap: `${spacing}px`,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              background: grey[300],
              borderBottomLeftRadius: `${spacing * 2}px`,
              borderBottomRightRadius: `${spacing * 2}px`,
              boxShadow: `0 1px 2px ${grey[900]}`,
            }}
          >
            <Container
              sx={{
                p: `${spacing}px`,
                background: grey[100],
                borderBottomLeftRadius: `${spacing * 2}px`,
                borderBottomRightRadius: `${spacing * 2}px`,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <AdminIcon sx={{ fontSize: '3rem', color: accent900 }} />
                <Box sx={{ ml: `${spacing}px` }}>
                  <Typography variant="h5" sx={{ color: accent900 }}>
                    Administrator
                  </Typography>
                  <Typography variant="caption" sx={{ color: grey[700] }}>
                    Administrator access
                  </Typography>
                </Box>
              </Box>
            </Container>
          </Box>
          <Box
            sx={{
              p: isDesktop ? `${spacing}px` : 0,
              background: grey[300],
              borderRadius: `${radius}px`,
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Container
              sx={{
                p: '10px',
                background: grey[200],
                borderRadius: `${radius}px`,
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  p: `${spacing}px`,
                  gap: `${spacing}px`,
                  background: grey[100],
                  boxShadow: `0 1px 2px ${grey[900]}`,
                  borderRadius: `${radius}px`,
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box
                  sx={{
                    height: 'fit-content',
                    gap: `${spacing}px`,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <DocumentIcon sx={{ fontSize: '4rem', color: accent800 }} />
                  <Box>
                    <Typography variant="h6">
                      Risk Assessment Document
                    </Typography>
                    <Typography variant="caption">
                      View, Upload, Edit, and Delete Risk Assessment Documents.
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    p: isDesktop ? `${spacing * 2}px` : `${spacing}px`,
                    gap: `${spacing}px`,
                    background: grey[50],
                    borderRadius: `${radius}px`,
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Box>
                      <Typography sx={{ fontWeight: 500 }}>
                        Attach Document:
                      </Typography>
                      <Typography>
                        Proceed below to upload a document (one document at a
                        time).
                      </Typography>
                    </Box>
                    <IconButton
                      onClick={() => {
                        setAttachDocPaneState(!attachDocPaneState);
                      }}
                      size="small"
                    >
                      {attachDocPaneState ? <LessIcon /> : <MoreIcon />}
                    </IconButton>
                  </Box>
                  <Collapse in={attachDocPaneState}>
                    <Box
                      sx={{
                        gap: `${spacing}px`,
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Box>
                        <Step
                          spacing={spacing}
                          number={1}
                          description="Upload the document by selecting or dropping the file."
                        />
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <Box
                            sx={{
                              p: 2,
                              borderRadius: `${radius}px`,
                              background: isDragActive
                                ? lightBlue[50]
                                : grey[50],
                            }}
                          >
                            <Box
                              {...getRootProps()}
                              sx={{
                                p: `${spacing}px`,
                                minHeight: '30vh',
                                background: isDragActive
                                  ? lightBlue[50]
                                  : grey[50],
                                borderColor: isDragActive
                                  ? primary900
                                  : primary500,
                                borderStyle: 'dashed',
                                borderRadius: `${radius}px`,
                                cursor: 'pointer',
                                flexGrow: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                              }}
                            >
                              <Box
                                sx={{
                                  background: isDragActive
                                    ? lightBlue[50]
                                    : grey[50],
                                  flexGrow: 1,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <Collapse in={isDragActive}>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Typography
                                      variant="h5"
                                      sx={{
                                        color: primary900,
                                      }}
                                    >
                                      Drop file here to upload
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      sx={{
                                        textAlign: 'center',
                                        '&>span': {
                                          fontWeight: 500,
                                          color: accent800,
                                        },
                                      }}
                                    >
                                      Accepted file types: <span>PDF</span>
                                    </Typography>
                                  </Box>
                                </Collapse>
                                <Collapse in={!postFile && !isDragActive}>
                                  <Typography
                                    sx={{
                                      mb: `${spacing}px`,
                                      color: grey[400],
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <DocumentIcon sx={{ fontSize: '5rem' }} />
                                    No files selected
                                  </Typography>
                                  <Box
                                    sx={{
                                      background: isDragActive
                                        ? primary500
                                        : grey[50],
                                      borderRadius: `${radius}px`,
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        '&>span': { fontWeight: 500 },
                                      }}
                                    >
                                      <span>Choose a file</span> or drag it here
                                    </Typography>

                                    <Typography
                                      variant="caption"
                                      sx={{
                                        '&>span': {
                                          fontWeight: 500,
                                          color: accent800,
                                        },
                                      }}
                                    >
                                      Accepted file types: <span>PDF</span>
                                    </Typography>
                                  </Box>
                                </Collapse>

                                {postFile ? (
                                  <Box
                                    sx={{
                                      background: isDragActive
                                        ? primary500
                                        : grey[50],
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        position: 'relative',
                                        width: '5rem',
                                        height: '5rem',
                                        background: posting
                                          ? green[500]
                                          : 'transparent',
                                        borderColor: posting
                                          ? green[500]
                                          : grey[50],
                                        borderStyle: 'solid',
                                        borderWidth: `${spacing / 3}px`,
                                        borderRadius: '100vw',
                                        flexShrink: 0,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        transition: 'all 0.30s ease',
                                      }}
                                    >
                                      <DocumentIcon
                                        sx={{
                                          color: posting
                                            ? grey[50]
                                            : green[500],
                                          fontSize: posting ? '3rem' : '5rem',
                                          transition: 'all 0.30s ease',
                                        }}
                                      />
                                      {posting && (
                                        <CircularProgress
                                          size="5rem"
                                          sx={{
                                            color: grey[50],
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            zIndex: 1,
                                          }}
                                          variant="determinate"
                                          value={postProgress}
                                        />
                                      )}
                                    </Box>
                                    <Typography
                                      variant="h6"
                                      sx={{
                                        fontWeight: 500,
                                        color: green[500],
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      {postFile.name}
                                    </Typography>
                                    <Typography
                                      variant="caption"
                                      sx={{ fontWeight: 500 }}
                                    >
                                      {(postFile.size / 1000).toFixed(2)} KB
                                    </Typography>
                                  </Box>
                                ) : null}
                              </Box>
                              <input
                                {...getInputProps()}
                                id="docUpload"
                                style={{ display: 'none' }}
                              />
                            </Box>
                          </Box>
                          <Collapse in={postFile && !posted}>
                            <Box
                              sx={{
                                flexGrow: 1,
                                gap: 1,
                                display: 'flex',
                              }}
                            >
                              <Button
                                onClick={() => {
                                  setPostFile(null);
                                }}
                                variant="outlined"
                                sx={{
                                  flexGrow: 1,
                                  borderColor: secondary500,
                                  color: secondary500,
                                  '&:hover': {
                                    borderColor: secondary900,
                                    color: secondary900,
                                  },
                                }}
                                startIcon={<CloseIcon />}
                                disabled={posting}
                              >
                                Remove
                              </Button>
                              <Button
                                onClick={() => {
                                  uploadDoc(postFile);
                                }}
                                sx={{
                                  flexGrow: 1,
                                  background: secondary500,
                                  color: grey[50],
                                  '&:hover': {
                                    background: secondary900,
                                  },
                                  '&.Mui-disabled': {
                                    background: grey[300],
                                  },
                                }}
                                startIcon={<UploadIcon />}
                                disabled={posting}
                              >
                                Upload
                              </Button>
                            </Box>
                          </Collapse>
                          <Collapse in={posted}>
                            <Box
                              sx={{
                                p: '30px',
                                my: '15px',
                                gap: '15px',
                                background: grey[50],
                                borderRadius: '5px',
                                display: 'flex',
                                flexDirection: 'column',
                                '& .MuiTextField-root': {
                                  background: grey[50],
                                  borderColor: green[500],
                                },
                              }}
                            >
                              <Typography variant="body2" sx={{ mb: 2 }}>
                                Uploaded document details:
                              </Typography>
                              <TextField
                                id="name"
                                name="name"
                                label="Document name"
                                fullWidth
                                size="small"
                                InputProps={{
                                  readOnly: true,
                                }}
                                value={postName}
                              />
                              <Box
                                sx={{
                                  gap: '15px',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <TextField
                                  id="link"
                                  name="link"
                                  label="Document link"
                                  fullWidth
                                  size="small"
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                  value={postLink}
                                />
                                <Tooltip
                                  arrow
                                  placement="top"
                                  title="Copy link"
                                >
                                  <IconButton
                                    onClick={() => {
                                      navigator.clipboard.writeText(postLink);
                                      setPostFileLinkCopied(true);
                                    }}
                                  >
                                    <CopyIcon color="inherit" />
                                  </IconButton>
                                </Tooltip>
                                <Collapse
                                  orientation="horizontal"
                                  in={postFileLinkCopied}
                                >
                                  <Typography
                                    variant="body2"
                                    noWrap
                                    sx={{
                                      mr: '15px',
                                      flexShrink: 0,
                                      fontWeight: 500,
                                    }}
                                  >
                                    Link copied!
                                  </Typography>
                                </Collapse>
                              </Box>
                            </Box>
                            <label htmlFor="docUpload">
                              <Button
                                onClick={() => {
                                  setPosted(false);
                                  setPostFile(null);
                                }}
                                component="span"
                                variant="outlined"
                                fullWidth
                                sx={{
                                  background: secondary500,
                                  color: grey[50],
                                  '&:hover': {
                                    background: secondary900,
                                  },
                                  '&.Mui-disabled': {
                                    background: grey[300],
                                  },
                                }}
                              >
                                Change file
                              </Button>
                            </label>
                          </Collapse>
                        </Box>
                      </Box>
                      <Box>
                        <Step
                          spacing={spacing}
                          number={2}
                          description="Enter customer account ID."
                        />
                        <Box sx={{ p: `${spacing}px`, flexGrow: 1 }}>
                          <TextField
                            label="Account ID"
                            type="number"
                            value={postAccountID}
                            onChange={postAccountIDChange}
                            error={postAccountIDError}
                            helperText={
                              postAccountIDError ? postAccountIDErrorText : null
                            }
                            required
                            fullWidth
                          />
                        </Box>
                      </Box>
                      <Button
                        onClick={() => {
                          postRADoc();
                        }}
                        startIcon={<SaveIcon />}
                        fullWidth
                        sx={{
                          background: accent800,
                          color: grey[50],
                          '&:hover': {
                            background: accent900,
                          },
                        }}
                      >
                        Save
                      </Button>
                    </Box>
                  </Collapse>
                </Box>
                <Box
                  sx={{
                    p: isDesktop ? `${spacing * 2}px` : `${spacing}px`,
                    gap: `${spacing}px`,
                    background: grey[50],
                    borderRadius: `${radius}px`,
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Box>
                      <Typography sx={{ fontWeight: 500 }}>
                        Existing Documents:
                      </Typography>
                      <Typography>
                        View, edit, or delete documents below.
                      </Typography>
                    </Box>
                    <IconButton
                      onClick={() => {
                        setExistingDocPaneState(!existingDocPaneState);
                      }}
                      size="small"
                    >
                      {existingDocPaneState ? <LessIcon /> : <MoreIcon />}
                    </IconButton>
                  </Box>
                  <Collapse in={existingDocPaneState}>
                    <Box
                      sx={{
                        gap: `${spacing}px`,
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Collapse in={selectedDoc}>
                        <Box
                          sx={{
                            p: '15px',
                            gap: '15px',
                            background: grey[100],
                            borderRadius: '5px',
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <Box
                            sx={{
                              gap: '15px',
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <Typography>Document information</Typography>
                            <Box
                              sx={{
                                gap: '15px',
                                display: 'flex',
                                alignItems: 'center',
                                '& .MuiIconButton-root': {
                                  color: accent800,
                                  '&:hover': {
                                    color: accent900,
                                  },
                                },
                              }}
                            >
                              <Tooltip title="Download">
                                <IconButton
                                  onClick={() => {
                                    window.open(selectedDocLink);
                                  }}
                                  size="small"
                                >
                                  <DownloadIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Delete">
                                <IconButton
                                  onClick={() => {
                                    delRADoc(selectedDoc);
                                  }}
                                  size="small"
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Edit">
                                <IconButton
                                  onClick={() => {
                                    setHasChanges(true);
                                  }}
                                  size="small"
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Close">
                                <IconButton
                                  onClick={() => {
                                    setSelectedDoc(null);
                                  }}
                                  size="small"
                                >
                                  <CloseIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              gap: '15px',
                              display: 'flex',
                              flexDirection: 'column',
                              '& .MuiTextField-root': {
                                background: grey[50],
                                borderRadius: '5px',
                              },
                            }}
                          >
                            <TextField
                              label="Name"
                              fullWidth
                              size="small"
                              value={selectedDocName}
                              onChange={selectedDocNameChange}
                            />
                            <TextField
                              label="Account ID"
                              fullWidth
                              size="small"
                              type="number"
                              value={selectedDocAccountID}
                              onChange={selectedDocAccountIDChange}
                            />
                            <Box
                              sx={{
                                gap: '15px',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <TextField
                                label="Link"
                                size="small"
                                value={selectedDocLink}
                                InputProps={{
                                  readOnly: true,
                                }}
                                sx={{ flexGrow: 1 }}
                              />
                              <IconButton
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    selectedDocLink
                                  );
                                  setSelectedDocLinkCopied(true);
                                }}
                              >
                                <CopyIcon />
                              </IconButton>
                              <Collapse
                                orientation="horizontal"
                                in={selectedDocLinkCopied}
                              >
                                <Typography
                                  variant="body2"
                                  noWrap
                                  sx={{
                                    mr: '15px',
                                    flexShrink: 0,
                                    fontWeight: 500,
                                  }}
                                >
                                  Link copied!
                                </Typography>
                              </Collapse>
                            </Box>
                            <Collapse in={hasChanges}>
                              <Box
                                sx={{
                                  gap: '15px',
                                  flexGrow: 1,
                                  display: 'flex',
                                }}
                              >
                                <Button
                                  onClick={() => {
                                    resetChanges();
                                  }}
                                  fullWidth
                                  sx={{
                                    background: grey[200],
                                    color: accent800,
                                    '&:hover': {
                                      background: grey[300],
                                      color: accent900,
                                    },
                                  }}
                                >
                                  Reset
                                </Button>
                                <Button
                                  onClick={() => {
                                    putRADoc();
                                  }}
                                  fullWidth
                                  sx={{
                                    background: accent800,
                                    color: grey[50],
                                    '&:hover': { background: accent900 },
                                  }}
                                >
                                  Save changes
                                </Button>
                              </Box>
                            </Collapse>
                          </Box>
                        </Box>
                      </Collapse>
                      {/* <FormControl id="asynchronous-demo">
                        <FormLabel>Asynchronous</FormLabel>
                        <Autocomplete
                          placeholder="Asynchronous"
                          open={openExistingDocs}
                          onOpen={() => {
                            setOpenExistingDocs(true);
                          }}
                          onClose={() => {
                            setOpenExistingDocs(false);
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option.title === value.title
                          }
                          getOptionLabel={(option) => option.name}
                          options={options}
                          loading={loadingExistingDocs}
                          endDecorator={
                            loadingExistingDocs ? (
                              <CircularProgress
                                size="sm"
                                sx={{ bgcolor: 'background.surface' }}
                              />
                            ) : null
                          }
                          value={selectedExistingDoc}
                          onSelect={searchHandler}
                        />
                      </FormControl> */}

                      <Box
                        sx={{
                          maxHeight: '50vh',
                          overflowY: 'auto',
                        }}
                      >
                        {!existingDocs && (
                          <Box
                            sx={{
                              minHeight: '30vh',
                              flexGrow: 1,
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              color: grey[400],
                            }}
                          >
                            <Typography variant="h4">
                              Loading documents...
                            </Typography>
                          </Box>
                        )}

                        {existingDocs &&
                          existingDocs.map((existingDoc, index) => (
                            <Box key={existingDoc.id}>
                              <Box
                                sx={{
                                  py: selectedDoc
                                    ? selectedDoc.id === existingDoc.id
                                      ? '15px'
                                      : 0
                                    : 0,
                                  px: '15px',
                                  gap: '15px',
                                  background: selectedDoc
                                    ? selectedDoc.id === existingDoc.id
                                      ? grey[200]
                                      : null
                                    : null,
                                  borderRadius: '5px',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                }}
                              >
                                <Box
                                  sx={{
                                    gap: '15px',
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <IconButton size="small">
                                    <DocumentAttachmentIcon />
                                  </IconButton>
                                  <Box
                                    sx={{
                                      maxWidth: '40ch',
                                      display: 'flex',
                                      flexDirection: 'column',
                                    }}
                                  >
                                    <Typography noWrap>
                                      {existingDoc.name}
                                    </Typography>
                                    <Typography noWrap variant="caption">
                                      {existingDoc.accountID}
                                    </Typography>
                                    <Typography noWrap variant="caption">
                                      {existingDoc.link}
                                    </Typography>
                                  </Box>
                                </Box>
                                <Button
                                  onClick={() => {
                                    setSelectedDoc(existingDoc);
                                  }}
                                  sx={{
                                    background: accent800,
                                    color: grey[50],
                                    '&:hover': {
                                      background: accent900,
                                    },
                                  }}
                                >
                                  <span
                                    style={{
                                      minWidth: '10ch',
                                    }}
                                  >
                                    {selectedDoc
                                      ? selectedDoc.id === existingDoc.id
                                        ? 'Selected'
                                        : 'Select'
                                      : 'Select'}
                                  </span>
                                </Button>
                              </Box>
                              <Divider
                                sx={{
                                  my:
                                    index < existingDocs.length - 1
                                      ? '10px'
                                      : 0,
                                  borderBottomStyle:
                                    index < existingDocs.length - 1
                                      ? 'solid'
                                      : 'none',
                                }}
                              />
                            </Box>
                          ))}
                      </Box>
                    </Box>
                  </Collapse>
                </Box>
              </Box>
            </Container>
          </Box>
          <Footer />
        </Box>
      )}
    </Box>
  );
}
