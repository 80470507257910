// Components
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import Footer from '../components/footer';

// Utilities
import { IsDesktop } from '../utilities/mediaQuery';
import moment from 'moment';
import emailjs from '@emailjs/browser';
import { keyEmailJs } from '../utilities/emailjsController';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../utilities/firebaseController';

// Colors
import { green, grey, red } from '@mui/material/colors';
import {
  accent900,
  accent800,
  primary500,
  secondary500,
} from '../utilities/colors';

// Icons
import DashboardIcon from '@mui/icons-material/Dashboard';
import MediaQuery from 'react-responsive';

// Assets
import imageSupport from '../assets/mr-waster-desk.png';
import { notifs } from '../components/notifications';

export default function Contact() {
  // STYLES
  // Generic
  var isDesktop = IsDesktop();
  // Specific
  const mainContainer = {
    px: isDesktop ? '15px' : 0,
    flexGrow: 1,
    gap: '15px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  };

  // VARIABLES

  const [contactFullname, setContactFullname] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactMobileNumber, setContactMobileNumber] = useState('');
  const [contactSubject, setContactSubject] = useState('');
  const [contactDetail, setContactDetail] = useState('');

  const [contactFullnameError, setContactFullnameError] = useState(false);
  const [contactEmailError, setContactEmailError] = useState(false);
  const [contactMobileNumberError, setContactMobileNumberError] =
    useState(false);
  const [contactSubjectError, setContactSubjectError] = useState(false);
  const [contactDetailError, setContactDetailError] = useState(false);

  const [contactFullnameErrorMessage, setContactFullnameErrorMessage] =
    useState(null);
  const [contactEmailErrorMessage, setContactEmailErrorMessage] =
    useState(null);
  const [contactMobileNumberErrorMessage, setContactMobileNumberErrorMessage] =
    useState(null);
  const [contactSubjectErrorMessage, setContactSubjectErrorMessage] =
    useState(null);
  const [contactDetailErrorMessage, setContactDetailErrorMessage] =
    useState(null);

  const [sendingContactTicket, setSendingContactTicket] = useState(false);

  // FUNCTIONS
  const contactFullnameChange = (e) => {
    let contactFullnameTemp = e.target.value;
    if (contactFullnameTemp.length < 1) {
      setContactFullnameError(true);
      setContactFullnameErrorMessage('Please provide your name');
    } else if (contactFullnameTemp.length < 3) {
      setContactFullnameError(true);
      setContactFullnameErrorMessage('Name should be at least 2 characters');
    } else {
      setContactFullnameError(false);
      setContactFullnameErrorMessage(null);
    }
    setContactFullname(contactFullnameTemp);
  };
  const contactEmailChange = (e) => {
    let contactEmailTemp = e.target.value;
    let contactEmailAt0 = e.target.value.split('@')[0];
    let contactEmailAt1 = e.target.value.split('@')[1];
    if (contactEmailTemp.length < 1) {
      setContactEmailError(true);
      setContactEmailErrorMessage('Please provide your email');
    } else if (
      !contactEmailAt0 ||
      !contactEmailAt1 ||
      !contactEmailAt1.split('.')[0] ||
      !contactEmailAt1.split('.')[1]
    ) {
      setContactEmailError(true);
      setContactEmailErrorMessage('Please provide a valid email');
    } else {
      setContactEmailError(false);
      setContactEmailErrorMessage(null);
    }
    setContactEmail(contactEmailTemp);
  };
  const contactMobileNumberChange = (e) => {
    let contactMobileNumberTemp = e.target.value;
    if (contactMobileNumberTemp.length < 1) {
      setContactMobileNumberError(true);
      setContactMobileNumberErrorMessage('Please provide your mobile number');
    } else {
      setContactMobileNumberError(false);
      setContactMobileNumberErrorMessage(null);
    }
    setContactMobileNumber(contactMobileNumberTemp);
  };
  const contactSubjectChange = (e) => {
    let contactSubjectTemp = e.target.value;
    if (contactSubjectTemp.length < 1) {
      setContactSubjectError(true);
      setContactSubjectErrorMessage('Please provide a subject');
    } else {
      setContactSubjectError(false);
      setContactSubjectErrorMessage(null);
    }
    setContactSubject(contactSubjectTemp);
  };
  const contactDetailChange = (e) => {
    let contactDetailTemp = e.target.value;
    if (contactDetailTemp.length < 1) {
      setContactDetailError(true);
      setContactDetailErrorMessage('Please provide some details');
    } else {
      setContactDetailError(false);
      setContactDetailErrorMessage(null);
    }
    setContactDetail(contactDetailTemp);
  };
  const sendContactTicket = (e) => {
    e.preventDefault();
    setSendingContactTicket(true);
    if (
      contactFullnameError ||
      contactEmailError ||
      contactMobileNumberError ||
      contactSubjectError ||
      contactDetailError
    ) {
      return;
    }
    emailjs
      .sendForm('wasterapp', 'contact_ticket', e.target, keyEmailJs)
      .then(() => {
        notifs.push({
          type: 1,
          background: green[500],
          content: (
            <Box>
              <Typography
                variant="body2"
                sx={{ '&>span': { fontWeight: 500 } }}
              >
                Contact ticket sent! We will look into it.
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  '&>span': {
                    fontWeight: 500,
                  },
                }}
              >
                We sent a copy to your email.
                <br />
                Please check your spam/junk folder.
              </Typography>
            </Box>
          ),
        });
        console.log('Contact ticket sent successfully!');
        setSendingContactTicket(false);
      })
      .catch((err) => {
        notifs.push({
          type: 1,
          background: red[500],
          content: (
            <Box>
              <Typography
                variant="body2"
                sx={{ '&>span': { fontWeight: 500 } }}
              >
                Failed to send contact ticket.
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  '&>span': {
                    fontWeight: 500,
                  },
                }}
              >
                Email us directly at <span>app@waster.com.au</span>.
              </Typography>
            </Box>
          ),
        });
        console.log('Contact ticket sent successfully!');
        console.log(err);
        setSendingContactTicket(false);
      });
  };
  return (
    <Box sx={mainContainer}>
      <Box
        sx={{
          background: grey[300],
          borderBottomLeftRadius: '30px',
          borderBottomRightRadius: '30px',
          boxShadow: `0 1px 2px ${grey[900]}`,
        }}
      >
        <Container
          sx={{
            p: '15px',
            background: grey[100],
            borderBottomLeftRadius: '30px',
            borderBottomRightRadius: '30px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <DashboardIcon sx={{ fontSize: '3rem', color: accent900 }} />
            <Box sx={{ ml: '15px' }}>
              <Typography variant="h5" sx={{ color: accent900 }}>
                Contact Us
              </Typography>
              <MediaQuery maxWidth={479}>
                <Typography variant="caption" sx={{ color: grey[700] }}>
                  Please contact us for any queries.
                </Typography>
              </MediaQuery>
              <MediaQuery minWidth={480}>
                <Typography variant="caption" sx={{ color: grey[700] }}>
                  Please contact our friendly customer service team for any
                  queries regarding our services.
                </Typography>
              </MediaQuery>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box sx={{ p: '15px', background: grey[200], borderRadius: '5px' }}>
        <Container
          sx={{ py: '30px', background: grey[100], borderRadius: '5px' }}
        >
          <Box
            sx={{
              borderRadius: '5px',
              flexGrow: 1,
              display: 'flex',
              flexDirection: isDesktop ? 'row' : 'column-reverse',
            }}
          >
            <Box
              sx={{
                p: '15px',
                pr: isDesktop ? '30px' : '15px',
                width: isDesktop ? 'min-content' : null,
                background: secondary500,
                color: grey[50],
                borderRadius: '5px',
                gap: '15px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  m: '15px',
                  width: 'fit-content',
                  background: grey[50],
                  borderRadius: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img
                  src={imageSupport}
                  alt="Mr. Waster Desk"
                  style={{ maxWidth: isDesktop ? null : '100%' }}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Typography noWrap>Waste & Recycling Contact:</Typography>
                <Typography
                  variant="h4"
                  sx={{
                    mb: '15px',
                    '&> span': {
                      fontWeight: 500,
                      color: primary500,
                    },
                  }}
                >
                  <span>FRIENDLY</span> Customer Service Team Ready to Answer
                  Your Queries
                </Typography>
                <Typography>
                  If you have a query regarding our services, please contact our
                  friendly customer service team. We will be happy to help you.
                </Typography>
                <Typography>
                  Waster, of course, prides itself in its world-class customer
                  service team. We aim to answer every enquiries and do our jobs
                  to the best of our abilities.
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                p: isDesktop ? '30px' : '15px',
                my: isDesktop ? '15px' : 0,
                ml: isDesktop ? '-15px' : 0,
                mb: '15px',
                background: grey[50],
                borderRadius: '5px',
                boxShadow: `0 1px 2px ${grey[900]}`,
                gap: '15px',
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box>
                <Typography noWrap variant="h5">
                  Contact Form
                </Typography>
                <Typography noWrap variant="caption" sx={{ color: grey[500] }}>
                  Please complete the form below.
                </Typography>
              </Box>
              <Box
                id="contactForm"
                component="form"
                onSubmit={sendContactTicket}
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Box
                  sx={{
                    '&> .MuiTextField-root': {
                      mb: '15px',
                    },
                  }}
                >
                  <TextField
                    id="customerName"
                    name="customerName"
                    required
                    size="small"
                    label="Full name"
                    value={contactFullname}
                    onChange={contactFullnameChange}
                    error={contactFullnameError}
                    helperText={
                      contactFullnameError ? contactFullnameErrorMessage : null
                    }
                    fullWidth
                  />
                  <TextField
                    id="customerEmail"
                    name="customerEmail"
                    required
                    size="small"
                    type="email"
                    label="Email"
                    value={contactEmail}
                    onChange={contactEmailChange}
                    error={contactEmailError}
                    helperText={
                      contactEmailError ? contactEmailErrorMessage : null
                    }
                    fullWidth
                  />
                  <TextField
                    id="customerMobileNumber"
                    name="customerMobileNumber"
                    required
                    size="small"
                    label="Mobile number"
                    value={contactMobileNumber}
                    onChange={contactMobileNumberChange}
                    error={contactMobileNumberError}
                    helperText={
                      contactMobileNumberError
                        ? contactMobileNumberErrorMessage
                        : null
                    }
                    fullWidth
                  />
                  <Divider sx={{ mb: '15px' }} />
                  <TextField
                    required
                    id="messageSubject"
                    name="messageSubject"
                    label="Subject"
                    value={contactSubject}
                    onChange={contactSubjectChange}
                    error={contactSubjectError}
                    helperText={
                      contactSubjectError ? contactSubjectErrorMessage : null
                    }
                    fullWidth
                  />
                  <TextField
                    required
                    id="messageDetails"
                    name="messageDetails"
                    label="Details"
                    value={contactDetail}
                    onChange={contactDetailChange}
                    error={contactDetailError}
                    helperText={
                      contactDetailError ? contactDetailErrorMessage : null
                    }
                    fullWidth
                    multiline
                    rows={8}
                  />
                  <TextField
                    id="messageSentDate"
                    name="messageSentDate"
                    value={moment().format('MMMM D, YYYY')}
                    sx={{ display: 'none' }}
                  />
                  <TextField
                    id="messageSentTime"
                    name="messageSentTime"
                    value={moment().format('h:mm a')}
                    sx={{ display: 'none' }}
                  />
                </Box>
                <Button
                  fullWidth
                  type="submit"
                  disabled={sendingContactTicket}
                  sx={{
                    background: accent800,
                    color: grey[50],
                    '&:hover': {
                      background: accent900,
                    },
                    '&.Mui-disabled': {
                      background: grey[400],
                      color: grey[500],
                    },
                  }}
                >
                  {sendingContactTicket ? (
                    <CircularProgress sx={{ color: grey[500] }} size={24} />
                  ) : (
                    'Submit'
                  )}
                </Button>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
}
