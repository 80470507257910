import part1 from '../assets/customLogo/1.svg';
import part2 from '../assets/customLogo/2.svg';
import part3 from '../assets/customLogo/3.svg';
import { grey } from '@mui/material/colors';
export function WAppLogoL(props) {
  const { background } = props;
  return (
    <div
      style={{
        padding: '10px',
        boxShadow: background ? `0px 1px 2px ${grey[900]}` : null,
        borderRadius: '5px',
        height: '48px',
        width: '48px',
        position: 'relative',
        background: background,
      }}
    >
      <img src={part1} style={{ height: '48px', position: 'absolute' }} />
      <img src={part2} style={{ height: '48px', position: 'absolute' }} />
      <img src={part3} style={{ height: '48px', position: 'absolute' }} />
    </div>
  );
}
export function WAppLogoMain(props) {
  const { background } = props;
  return (
    <div
      style={{
        padding: '10px',
        boxShadow: background ? `0px 1px 2px ${grey[900]}` : null,
        borderRadius: '5px',
        height: '100px',
        width: '100px',
        position: 'relative',
        background: background,
      }}
    >
      <img src={part1} style={{ height: '100px', position: 'absolute' }} />
      <img src={part2} style={{ height: '100px', position: 'absolute' }} />
      <img src={part3} style={{ height: '100px', position: 'absolute' }} />
    </div>
  );
}
