// Components
import { useEffect, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import Drawer from './components/drawer';

// Pages
import PageNotFound from './pages/404';
import Home from './pages/home';
import Dashboard from './pages/dashboard';
import Services from './pages/services';
import Contact from './pages/contact';
import Login from './pages/login';
import Register from './pages/register';
import Profile from './pages/profile';

import Admin from './pages/admin';
import Maintenance from './pages/maintenance';

// Router control
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Utilities
import { IsDesktop } from './utilities/mediaQuery';
import { history } from './utilities/history';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './utilities/firebaseController';
import axios from 'axios';

// Colors
import { background700, background800 } from './utilities/colors';
import { grey } from '@mui/material/colors';

// Icons
import CloseIcon from '@mui/icons-material/Close';
import Notifications from './components/notifications';

export default function App() {
  // Maintenance
  // if (history.location.pathname !== '/maintenance') {
  //   history.push('maintenance');
  //   history.go(0);
  // }
  // STYLES
  // Generic
  var isDesktop = IsDesktop();

  // VARIABLES
  const [user, loading, error] = useAuthState(auth);
  const [isAdmin, setIsAdmin] = useState(false);
  const [notificationsPanel, setNotificationsPanel] = useState(null);

  // FUNCTIONS
  const getIsAdmin = (email) => {
    axios
      .get(
        `https://us-central1-wasterapp-9ea2a.cloudfunctions.net/wastedge/isAdmin/${email}`
      )
      .then((response) => {
        setIsAdmin(response.data.isAdmin);
      })
      .catch((error) => {
        console.log(error);
        setTimeout(() => {
          getIsAdmin(email);
        }, 6000);
      });
  };
  // EFFECTS
  if (history.location.pathname === '/') {
    history.push('/dashboard');
    history.go(0);
  }
  useEffect(() => {
    if (user) {
      getIsAdmin(user.email);
    }
  }, [user, loading, error]);

  useEffect(() => {
    setInterval(() => {
      setNotificationsPanel(<Notifications />);
    }, 1000);
  }, []);

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        background: `repeating-linear-gradient(
          45deg,
          ${background700},
          ${background700} 12px,
          ${background800} 12px,
          ${background800} 24px)`,
        display: 'flex',
        flexDirection: isDesktop ? 'row' : 'column-reverse',
        overflow: 'auto',
        position: 'relative',
      }}
    >
      {notificationsPanel}
      <Drawer isAdmin={isAdmin} />
      <Box
        sx={{
          mb: isDesktop ? 0 : '100px',
          height: '100vh',
          flexGrow: 1,
          display: 'flex',
          overflowY: 'auto',
        }}
      >
        <BrowserRouter>
          <Routes>
            <Route path={'home'} element={<Home />} />
            <Route path={'dashboard'} element={<Dashboard />} />
            <Route path={'services'} element={<Services />} />
            <Route path={'contact'} element={<Contact />} />
            <Route path={'login'} element={<Login />} />
            <Route path={'register'} element={<Register />} />
            <Route path={'profile'} element={<Profile />} />
            <Route path={'admin'} element={<Admin />} />
            {/* Maintenance */}
            {/* <Route path={'maintenance'} element={<Maintenance />} /> */}
            <Route path={'*'} element={<PageNotFound />} />
          </Routes>
        </BrowserRouter>
      </Box>
    </Box>
  );
}
