export function RouteCycles(routeCycle) {
  var forEvery = routeCycle;
  var unit = 'weeks';
  if (routeCycle === 'WK') {
    forEvery = 7;
    unit = 'days';
  } else if (routeCycle === 'BW') {
    forEvery = 14;
    unit = 'days';
  } else if (routeCycle === 'MO') {
    forEvery = 1;
    unit = 'months';
  } else if (
    routeCycle === 'QT' ||
    routeCycle === 'Q1' ||
    routeCycle === 'Q2' ||
    routeCycle === 'Q3' ||
    routeCycle === 'Q4' ||
    routeCycle === 'Q5'
  ) {
    forEvery = 3;
    unit = 'months';
  } else if (routeCycle === 'SA') {
    forEvery = 6;
    unit = 'months';
  } else if (routeCycle === 'AN') {
    forEvery = 1;
    unit = 'years';
  } else if (routeCycle === 'W3') {
    forEvery = 3;
    unit = 'weeks';
  } else if (routeCycle === 'W4') {
    forEvery = 4;
    unit = 'weeks';
  } else if (routeCycle === 'W5') {
    forEvery = 5;
    unit = 'weeks';
  } else if (routeCycle === 'W6') {
    forEvery = 6;
    unit = 'weeks';
  } else if (routeCycle === 'W7') {
    forEvery = 7;
    unit = 'weeks';
  } else if (routeCycle === 'W8') {
    forEvery = 8;
    unit = 'weeks';
  } else if (routeCycle === 'W9') {
    forEvery = 9;
    unit = 'weeks';
  } else if (routeCycle === 'W10') {
    forEvery = 10;
    unit = 'weeks';
  } else if (routeCycle === 'W11') {
    forEvery = 11;
    unit = 'weeks';
  } else if (routeCycle === 'W12') {
    forEvery = 12;
    unit = 'weeks';
  } else if (routeCycle === 'W13') {
    forEvery = 13;
    unit = 'weeks';
  } else if (routeCycle === 'W14') {
    forEvery = 14;
    unit = 'weeks';
  } else if (routeCycle === 'W15') {
    forEvery = 15;
    unit = 'weeks';
  } else if (routeCycle === 'W16') {
    forEvery = 16;
    unit = 'weeks';
  } else if (routeCycle === 'W17') {
    forEvery = 17;
    unit = 'weeks';
  } else if (routeCycle === 'W18') {
    forEvery = 18;
    unit = 'weeks';
  } else if (routeCycle === 'W19') {
    forEvery = 19;
    unit = 'weeks';
  } else if (routeCycle === 'W20') {
    forEvery = 20;
    unit = 'weeks';
  } else if (routeCycle === 'W21') {
    forEvery = 21;
    unit = 'weeks';
  } else if (routeCycle === 'W22') {
    forEvery = 22;
    unit = 'weeks';
  } else if (routeCycle === 'W23') {
    forEvery = 23;
    unit = 'weeks';
  } else if (routeCycle === 'W24') {
    forEvery = 24;
    unit = 'weeks';
  } else if (routeCycle === 'W25') {
    forEvery = 25;
    unit = 'weeks';
  } else if (routeCycle === 'W26') {
    forEvery = 26;
    unit = 'weeks';
  } else if (routeCycle === 'W27') {
    forEvery = 27;
    unit = 'weeks';
  } else if (routeCycle === 'W28') {
    forEvery = 28;
    unit = 'weeks';
  } else if (routeCycle === 'W29') {
    forEvery = 29;
    unit = 'weeks';
  } else if (routeCycle === 'W30') {
    forEvery = 30;
    unit = 'weeks';
  } else if (routeCycle === 'W31') {
    forEvery = 31;
    unit = 'weeks';
  } else if (routeCycle === 'W32') {
    forEvery = 32;
    unit = 'weeks';
  } else if (routeCycle === 'W33') {
    forEvery = 33;
    unit = 'weeks';
  } else if (routeCycle === 'W34') {
    forEvery = 34;
    unit = 'weeks';
  } else if (routeCycle === 'W35') {
    forEvery = 35;
    unit = 'weeks';
  } else if (routeCycle === 'W36') {
    forEvery = 36;
    unit = 'weeks';
  } else if (routeCycle === 'W37') {
    forEvery = 37;
    unit = 'weeks';
  } else if (routeCycle === 'W38') {
    forEvery = 38;
    unit = 'weeks';
  } else if (routeCycle === 'W39') {
    forEvery = 39;
    unit = 'weeks';
  } else if (routeCycle === 'W40') {
    forEvery = 40;
    unit = 'weeks';
  } else if (routeCycle === 'W41') {
    forEvery = 41;
    unit = 'weeks';
  } else if (routeCycle === 'W42') {
    forEvery = 42;
    unit = 'weeks';
  } else if (routeCycle === 'W43') {
    forEvery = 43;
    unit = 'weeks';
  } else if (routeCycle === 'W44') {
    forEvery = 44;
    unit = 'weeks';
  } else if (routeCycle === 'W45') {
    forEvery = 45;
    unit = 'weeks';
  } else if (routeCycle === 'W46') {
    forEvery = 46;
    unit = 'weeks';
  } else if (routeCycle === 'W47') {
    forEvery = 47;
    unit = 'weeks';
  } else if (routeCycle === 'W48') {
    forEvery = 48;
    unit = 'weeks';
  } else if (routeCycle === 'W49') {
    forEvery = 49;
    unit = 'weeks';
  } else if (routeCycle === 'W50') {
    forEvery = 50;
    unit = 'weeks';
  } else if (routeCycle === 'W51') {
    forEvery = 51;
    unit = 'weeks';
  } else if (routeCycle === 'W52') {
    forEvery = 52;
    unit = 'weeks';
  } else if (routeCycle === 'W53') {
    forEvery = 53;
    unit = 'weeks';
  } else if (routeCycle === 'W54') {
    forEvery = 54;
    unit = 'weeks';
  } else if (routeCycle === 'W55') {
    forEvery = 55;
    unit = 'weeks';
  } else if (routeCycle === 'W56') {
    forEvery = 56;
    unit = 'weeks';
  } else if (routeCycle === 'W57') {
    forEvery = 57;
    unit = 'weeks';
  } else if (routeCycle === 'W58') {
    forEvery = 58;
    unit = 'weeks';
  } else if (routeCycle === 'W59') {
    forEvery = 59;
    unit = 'weeks';
  } else if (routeCycle === 'W60') {
    forEvery = 60;
    unit = 'weeks';
  } else if (routeCycle === 'W61') {
    forEvery = 61;
    unit = 'weeks';
  } else if (routeCycle === 'W62') {
    forEvery = 62;
    unit = 'weeks';
  } else if (routeCycle === 'W63') {
    forEvery = 63;
    unit = 'weeks';
  } else if (routeCycle === 'W64') {
    forEvery = 64;
    unit = 'weeks';
  } else if (routeCycle === 'W65') {
    forEvery = 65;
    unit = 'weeks';
  } else if (routeCycle === 'W66') {
    forEvery = 66;
    unit = 'weeks';
  } else if (routeCycle === 'W67') {
    forEvery = 67;
    unit = 'weeks';
  } else if (routeCycle === 'W68') {
    forEvery = 68;
    unit = 'weeks';
  } else if (routeCycle === 'W69') {
    forEvery = 69;
    unit = 'weeks';
  } else if (routeCycle === 'W70') {
    forEvery = 70;
    unit = 'weeks';
  } else if (routeCycle === 'W71') {
    forEvery = 71;
    unit = 'weeks';
  } else if (routeCycle === 'W72') {
    forEvery = 72;
    unit = 'weeks';
  } else if (routeCycle === 'W73') {
    forEvery = 73;
    unit = 'weeks';
  } else if (routeCycle === 'W74') {
    forEvery = 74;
    unit = 'weeks';
  } else if (routeCycle === 'W75') {
    forEvery = 75;
    unit = 'weeks';
  } else if (routeCycle === 'W76') {
    forEvery = 76;
    unit = 'weeks';
  } else if (routeCycle === 'W77') {
    forEvery = 77;
    unit = 'weeks';
  } else if (routeCycle === 'W78') {
    forEvery = 78;
    unit = 'weeks';
  } else if (routeCycle === 'W79') {
    forEvery = 79;
    unit = 'weeks';
  } else if (routeCycle === 'W80') {
    forEvery = 80;
    unit = 'weeks';
  } else if (routeCycle === 'W81') {
    forEvery = 81;
    unit = 'weeks';
  } else if (routeCycle === 'W82') {
    forEvery = 82;
    unit = 'weeks';
  } else if (routeCycle === 'W83') {
    forEvery = 83;
    unit = 'weeks';
  } else if (routeCycle === 'W84') {
    forEvery = 84;
    unit = 'weeks';
  } else if (routeCycle === 'W85') {
    forEvery = 85;
    unit = 'weeks';
  } else if (routeCycle === 'W86') {
    forEvery = 86;
    unit = 'weeks';
  } else if (routeCycle === 'W87') {
    forEvery = 87;
    unit = 'weeks';
  } else if (routeCycle === 'W88') {
    forEvery = 88;
    unit = 'weeks';
  } else if (routeCycle === 'W89') {
    forEvery = 89;
    unit = 'weeks';
  } else if (routeCycle === 'W90') {
    forEvery = 90;
    unit = 'weeks';
  } else if (routeCycle === 'W91') {
    forEvery = 91;
    unit = 'weeks';
  } else if (routeCycle === 'W92') {
    forEvery = 92;
    unit = 'weeks';
  } else if (routeCycle === 'W93') {
    forEvery = 93;
    unit = 'weeks';
  } else if (routeCycle === 'W94') {
    forEvery = 94;
    unit = 'weeks';
  } else if (routeCycle === 'W95') {
    forEvery = 95;
    unit = 'weeks';
  } else if (routeCycle === 'W96') {
    forEvery = 96;
    unit = 'weeks';
  } else if (routeCycle === 'W97') {
    forEvery = 97;
    unit = 'weeks';
  } else if (routeCycle === 'W98') {
    forEvery = 98;
    unit = 'weeks';
  } else if (routeCycle === 'W99') {
    forEvery = 99;
    unit = 'weeks';
  }

  return { forEvery: forEvery, unit: unit };
}
