// Components
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import Footer from '../components/footer';
import { notifs } from '../components/notifications';

// Utilities
import { IsDesktop } from '../utilities/mediaQuery';
import { auth } from '../utilities/firebaseController';
import { useAuthState } from 'react-firebase-hooks/auth';
import { history } from '../utilities/history';

// Colors
import { deepPurple, green, grey, teal } from '@mui/material/colors';
import {
  accent900,
  accent800,
  primary500,
  primary900,
} from '../utilities/colors';

// Assets
import { WAppLogoL, WAppLogoMain } from '../assets/customLogo';
import main1 from '../assets/1.jpg';
import banner1 from '../assets/mr-waster-bin.png';
import banner2 from '../assets/mr-waster-desk.png';
import RequestPickUpImg from '../assets/features/request_pick_up.png';
import ProfileImg from '../assets/features/profile.png';

// Icons
import Perk1 from '@mui/icons-material/EmojiEmotions';
import Perk2 from '@mui/icons-material/AccessTimeFilled';
import Perk3 from '@mui/icons-material/LocalOffer';
import NextIcon from '@mui/icons-material/ChevronRight';
import New1Icon from '@mui/icons-material/EventAvailable';
import New2Icon from '@mui/icons-material/AccountCircle';

export default function Home() {
  // STYLES
  // Generic
  var isDesktop = IsDesktop();
  const ml15 = { ml: '15px' };
  const flexGrow = { flexGrow: 1 };
  const flex = { display: 'flex' };
  const textCenter = { textAlign: 'center' };
  const textRightMobile = {
    textAlign: isDesktop ? 'right' : null,
  };
  const textBold = { fontWeight: 500 };
  const img256Mobile = { maxWidth: isDesktop ? null : '256px' };

  // Specific
  const mainContainer = {
    px: isDesktop ? '15px' : 0,
    gap: '15px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  };
  const headerMainBack = {
    flexGrow: 1,
    background: `url(${main1})`,
    backgroundSize: 'cover',
    backgroundPositionX: 'center',
    backgroundPositionY: 'bottom',
    borderBottomRightRadius: '30px',
    borderBottomLeftRadius: '30px',
    boxSizing: 'border-box',
    boxShadow: `0 1px 2px ${grey[900]}`,
    display: 'flex',
  };
  const headerMainFront = {
    minHeight: '640px',
    px: isDesktop ? '15px' : 0,
    flexGrow: 1,
    backgroundImage: `linear-gradient(rgba(0,22,59,0.5), rgba(0,22,59,1))`,
    backgroundBlendMode: 'multiply',
    borderStyle: 'solid',
    borderTopStyle: 'none',
    borderColor: grey[50],
    borderWidth: '15px',
    borderBottomRightRadius: '30px',
    borderBottomLeftRadius: '30px',
    display: 'flex',
  };
  const headerMainMargin = {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    '&>:first-child': {
      transform: 'translateY(0px)',
      transition: 'all 0.15s ease',
    },
    '&:hover': {
      '&>:first-child': {
        transform: 'translateY(-5px)',
      },
    },
  };
  const namePlate = {
    pb: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: grey[50],
  };
  const name1 = { fontWeight: 400, color: primary500 };
  const name2 = { fontWeight: 700, color: accent800 };
  const tagline = { color: grey[300] };
  const subHeaderMainContainer = {
    mb: '-15px',
    py: '30px',
    background: grey[50],
    borderRadius: '15px',
    display: 'flex',
    flexDirection: 'column',
  };
  const subHeaderMainTagline = {
    mb: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  const subHeaderMainRailsLeft = {
    mx: '15px',
    background: `repeating-linear-gradient(45deg, ${grey[50]}, ${grey[50]} 12px, ${accent800} 12px, ${accent800} 24px)`,
    height: '1.5rem',
    flexGrow: 1,
  };
  const subHeaderMainRailsRight = {
    mx: '15px',
    background: `repeating-linear-gradient(-45deg, ${grey[50]}, ${grey[50]} 12px, ${accent800} 12px, ${accent800} 24px)`,
    height: '1.5rem',
    flexGrow: 1,
  };
  const subHeaderChoicesContainer = {
    px: '15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '&> .MuiButton-root': {
      mt: '5px',
      background: accent800,
      color: grey[50],
      '&:hover': {
        background: accent900,
      },
    },
  };
  const perksContainer = {
    p: '15px',
    background: grey[200],
    borderRadius: '15px',
    boxShadow: `0 1px 2px ${grey[900]}`,
  };
  const perksMargin = {
    p: '15px',
    gap: '15px',
    background: grey[50],
    borderRadius: '5px',
    display: 'flex',
    flexDirection: isDesktop ? 'row' : 'column',
  };
  const perksItem = {
    maxWidth: isDesktop ? '30%' : 'auto',
    color: grey[800],
    '&>:first-child': {
      transform: 'scale(1)',
      transition: 'all 0.15s ease',
    },
    '&:hover': {
      '&>:first-child': {
        transform: 'scale(1.15)',
        transformOrigin: 'left',
        color: primary500,
      },
      '&> .MuiBox-root': {
        '&> .MuiTypography-body1': {
          color: primary500,
        },
      },
    },
  };
  const bannerContainer = {
    p: '15px',
    background: grey[200],
    borderRadius: '15px',
    boxShadow: `0 1px 2px ${grey[900]}`,
  };
  const bannerMargin = {
    p: '15px',
    background: grey[50],
    borderRadius: '5px',
    display: 'flex',
    '& img': {
      transform: 'translateY(0px)',
      transition: 'all 0.15s ease',
    },
    '&:hover': {
      '& img': {
        transform: 'translateY(-5px)',
      },
    },
  };
  const banner1GridContainer = {
    py: '30px',
    '&> .MuiGrid-item': {
      px: '15px',
      display: 'flex',
      justifyContent: 'center',
    },
  };
  const bannerGridItemPhoto = {
    mb: isDesktop ? 0 : '30px',
    alignItems: 'center',
  };
  const banner1GridItemText = {
    gap: '15px',
    flexDirection: 'column',
  };
  const banner2GridContainer = {
    py: '30px',
    flexDirection: isDesktop ? 'row-reverse' : null,
    '&> .MuiGrid-item': {
      px: '15px',
      display: 'flex',
      justifyContent: 'center',
    },
  };
  const banner2GridItemText = {
    gap: '15px',
    flexDirection: 'column',
    alignItems: isDesktop ? 'flex-end' : null,
    '&> .MuiButton-root': {
      background: accent800,
      color: grey[50],
      '&:hover': {
        background: accent900,
      },
    },
  };

  // VARIABLES
  const perks = [
    {
      icon: <Perk1 />,
      title: 'Customer focus',
      subtitle:
        'All cheap pricing includes market-leading customer service, invoicing, reporting, and call center.',
    },
    {
      icon: <Perk2 />,
      title: 'On time and reliable',
      subtitle:
        'All waste management services for smart businesses provided by fully accredited logistics and facility operators, ensuring safety and efficiency.',
    },
    {
      icon: <Perk3 />,
      title: 'Low-cost, flexible contracts',
      subtitle:
        'Pricing equivalent tothat enjoyed by major national chains - boosting your profitability. In addition, month-to-month agreeements.',
    },
  ];

  const [user, loading, error] = useAuthState(auth);

  // FUNCTIONS

  // EFFECTS
  useEffect(() => {
    if (user) {
      notifs.push({
        type: 1,
        background: green[700],
        content: (
          <Typography variant="body2" sx={{ '&>span': { fontWeight: 500 } }}>
            Welcome! Signed in as <span>{user.email}</span>.
          </Typography>
        ),
      });
    }
  }, [user, loading, error]);

  return (
    <Box sx={mainContainer}>
      <Box sx={headerMainBack}>
        <Box sx={headerMainFront}>
          <Container sx={headerMainMargin}>
            <Box sx={namePlate}>
              {isDesktop ? (
                <WAppLogoMain background={grey[50]} />
              ) : (
                <WAppLogoL background={grey[50]} />
              )}
              <Box sx={ml15}>
                <Box sx={flex}>
                  <Typography
                    noWrap
                    variant={isDesktop ? 'h2' : 'h4'}
                    sx={name1}
                  >
                    Waster
                  </Typography>
                  <Typography
                    noWrap
                    variant={isDesktop ? 'h2' : 'h4'}
                    sx={name2}
                  >
                    APP
                  </Typography>
                </Box>
                <Typography variant={isDesktop ? 'h5' : 'body1'} sx={tagline}>
                  Your Waster.com.au Portal
                </Typography>
              </Box>
            </Box>
            <Box sx={subHeaderMainContainer}>
              <Box sx={subHeaderMainTagline}>
                <Box sx={subHeaderMainRailsLeft} />
                <Typography variant="h5" sx={textCenter}>
                  WASTE MANAGEMENT FOR SMART BUSINESSES
                </Typography>
                <Box sx={subHeaderMainRailsRight} />
              </Box>
              <Box sx={subHeaderChoicesContainer}>
                <Typography>I'm looking for</Typography>
                <Button
                  onClick={() => {
                    window.open('https://waster.com.au/waste-recycling-shop/');
                  }}
                  fullWidth
                >
                  Bin Collections
                </Button>
                <Button
                  onClick={() => {
                    window.open('https://waster.com.au/grease-traps-cleaning/');
                  }}
                  fullWidth
                >
                  Grease Trap Cleaning
                </Button>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
      {/* <Box sx={perksContainer}>
        <Container
          sx={{
            p: '15px',
            background: grey[50],
            borderRadius: '5px',
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
          }}
        >
          <Typography variant="h6">
            What's new with{' '}
            <span style={{ color: primary500, fontWeight: 500 }}>Waster</span>
            <span style={{ color: accent800, fontWeight: 500 }}>APP</span>? ✨
          </Typography>
          <Grid
            container
            sx={{
              gap: '15px',
              display: 'flex',
              flexDirection: isDesktop ? 'row' : 'column',
              transition: 'all 0.15s ease',
            }}
          >
            <Grid
              item
              xs
              sx={{
                p: '15px',
                gap: '15px',
                flexGrow: 1,
                background: grey[100],
                borderRadius: '5px',
                borderStyle: 'solid',
                borderColor: deepPurple[500],
                borderWidth: '2px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                transition: 'all 0.3s ease',
                '&> .MuiBox-root:nth-of-type(1)': {
                  maxHeight: '256px',
                  overflow: 'hidden',
                  position: 'relative',
                  transition: 'all 0.3s ease',
                  display: 'flex',
                  flexDirecion: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  '&:hover': {
                    maxHeight: '100%',
                    '&> .MuiBox-root': {
                      background: `linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0))`,
                    },
                  },
                  '&> img': {
                    padding: isDesktop ? '10px' : 0,
                    maxWidth: isDesktop ? '720px' : '100%',
                    borderRadius: '15px',
                    borderStyle: 'solid',
                    borderColor: deepPurple[500],
                    borderWidth: '5px',
                    boxSizing: 'border-box',
                  },
                  '&> .MuiBox-root': {
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    position: 'absolute',
                    transition: 'all 0.3s ease',
                    background: `linear-gradient(rgba(0,0,0,0), ${grey[100]})`,
                  },
                },
                '&> .MuiBox-root:nth-of-type(2)': {
                  gap: '15px',
                  color: deepPurple[500],
                  display: 'flex',
                  alignItems: 'center',
                },
                '&> .MuiTypography-caption': {
                  color: grey[700],
                  maxWidth: isDesktop ? '75ch' : '100%',
                  textAlign: 'center',
                },
              }}
            >
              <Box>
                <img src={RequestPickUpImg} />
                <Box />
              </Box>
              <Box>
                <New1Icon sx={{ fontSize: '2.5rem' }} />
                <Typography variant="h5" sx={{ fontWeight: 500 }}>
                  Request Pick-up
                </Typography>
              </Box>
              <Typography variant="body2">
                You can now request extra pick-up service in the Dashboard and
                Services pages!
              </Typography>
              <Typography variant="caption">
                We believe that your time is worth gold and we try to save it as
                much as possible. So we decided to implement a simpler and
                faster way of requesting pick-up service with just two clicks!
              </Typography>
            </Grid>
            <Grid
              item
              xs
              sx={{
                p: '15px',
                gap: '15px',
                flexGrow: 1,
                background: grey[100],
                borderRadius: '5px',
                borderStyle: 'solid',
                borderColor: teal[500],
                borderWidth: '2px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                transition: 'all 0.3s ease',
                '&> .MuiBox-root:nth-of-type(1)': {
                  maxHeight: '256px',
                  overflow: 'hidden',
                  position: 'relative',
                  transition: 'all 0.3s ease',
                  display: 'flex',
                  flexDirecion: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  '&:hover': {
                    maxHeight: '100%',
                    '&> .MuiBox-root': {
                      background: `linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0))`,
                    },
                  },
                  '&> img': {
                    padding: isDesktop ? '10px' : 0,
                    maxWidth: isDesktop ? '720px' : '100%',
                    borderRadius: '15px',
                    borderStyle: 'solid',
                    borderColor: teal[500],
                    borderWidth: '5px',
                    boxSizing: 'border-box',
                  },
                  '&> .MuiBox-root': {
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    position: 'absolute',
                    transition: 'all 0.3s ease',
                    background: `linear-gradient(rgba(0,0,0,0), ${grey[100]})`,
                  },
                },
                '&> .MuiBox-root:nth-of-type(2)': {
                  gap: '15px',
                  color: teal[500],
                  display: 'flex',
                  alignItems: 'center',
                },
                '&> .MuiTypography-caption': {
                  color: grey[700],
                  maxWidth: isDesktop ? '75ch' : '100%',
                  textAlign: 'center',
                },
              }}
            >
              <Box>
                <img src={ProfileImg} />
                <Box />
              </Box>
              <Box>
                <New2Icon sx={{ fontSize: '2.5rem' }} />
                <Typography variant="h5" sx={{ fontWeight: 500 }}>
                  View your Waster profile
                </Typography>
              </Box>
              <Typography variant="body2">
                You can now view your account settings and details in the
                Profile page!
              </Typography>
              <Typography variant="caption">
                Here at Waster, we value the information that you provide us.
                They are secured in our database and only you and our team can
                take a look at them.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box> */}
      <Box sx={perksContainer}>
        <Container sx={perksMargin}>
          {perks.map((perk, index) => (
            <Box
              key={index}
              sx={{
                ...flex,
                ...flexGrow,
                ...perksItem,
              }}
            >
              {perk.icon}
              <Box sx={ml15}>
                <Typography variant="body1" sx={textBold}>
                  {perk.title}
                </Typography>
                <Typography variant="caption">{perk.subtitle}</Typography>
              </Box>
            </Box>
          ))}
        </Container>
      </Box>
      <Box sx={bannerContainer}>
        <Container sx={bannerMargin}>
          <Grid container sx={banner1GridContainer}>
            <Grid item xs={isDesktop ? 6 : 12} sx={bannerGridItemPhoto}>
              <img src={banner1} alt="Mr. Waster Bin" style={img256Mobile} />
            </Grid>
            <Grid item xs={isDesktop ? 6 : 12} sx={banner1GridItemText}>
              <Typography variant="h5">
                Waste Management for Smart Businesses!
              </Typography>
              <Typography variant="caption">
                Waster is an innovative solution for all your waste management
                and recycling needs. Additionally, we enable smart businesses to
                access the lowest cost/cheapest waste management, recycling, bin
                collection, removal and disposal prices available in the
                Australian garbage and recycling market whilst requiring no lock
                in contracts, no unjustified rate increases and no hidden costs.
                Waster operates in all metro regions throughout Australia,
                unlike other waste management companies in Australia!
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box sx={bannerContainer}>
        <Container sx={bannerMargin}>
          <Grid container sx={banner2GridContainer}>
            <Grid item xs={isDesktop ? 6 : 12} sx={bannerGridItemPhoto}>
              <img src={banner2} alt="Mr. Waster Desk" style={img256Mobile} />
            </Grid>
            <Grid item xs={isDesktop ? 6 : 12} sx={banner2GridItemText}>
              <Typography
                variant="body2"
                sx={{
                  ...textBold,
                  ...textRightMobile,
                }}
              >
                Low-Risk And With Flexible Agreements
              </Typography>
              <Typography variant="h5" sx={textRightMobile}>
                Designed for Small & Medium Businesses In Need Of Waste
                Management And Recycling Services
              </Typography>
              <Typography variant="body2" sx={textRightMobile}>
                We work with smart, small and medium businesses that needs waste
                management services just like yours. So, let us help you reduce
                costs and boost recycling! We are one of the best-reviewed waste
                management companies in Australia. Waste management for smart
                businesses!
              </Typography>
              <Typography variant="caption" sx={textRightMobile}>
                We believe in our wide community. So, we match customer
                donations to plant trees through lightGreenfleet. We also offer
                generous referral bonuses to customers who recommend friends to
                Waster.
              </Typography>
              <Button
                fullWidth
                onClick={() => {
                  history.push('contact');
                  history.go(0);
                }}
                endIcon={<NextIcon />}
              >
                Talk to our friendly customer service team
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
      {isDesktop ? <Footer /> : null}
    </Box>
  );
}
