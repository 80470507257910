// Components
import { Box, Button, Container, Typography } from '@mui/material';
// Utilities
import { actionCodeSettings } from '../utilities/firebaseController';
import { sendEmailVerification } from 'firebase/auth';

// Colors
import { green, grey, red } from '@mui/material/colors';
import { accent900, accent800 } from '../utilities/colors';
import { history } from '../utilities/history';

export default function Unregistered() {
  //VARIABLES

  // FUNCTIONS
  return (
    <Box
      sx={{
        p: '15px',
        flexGrow: 1,
        background: grey[100],
        borderRadius: '5px',
        boxShadow: `0 1px 2px ${grey[900]}`,
        display: 'flex',
      }}
    >
      <Container
        sx={{
          gap: '15px',
          minHeight: '50vh',
          flexGrow: 1,
          background: grey[50],
          borderRadius: '5px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6" sx={{ color: grey[600] }}>
            Not logged in
          </Typography>
          <Typography variant="body2" sx={{ color: grey[500] }}>
            Register or log in to see your&nbsp;
            {window.location.pathname.split('/')[1]}.
          </Typography>
        </Box>
        <Box sx={{ gap: '15px', display: 'flex', alignItems: 'center' }}>
          <Button
            onClick={() => {
              history.push('login');
              history.go(0);
            }}
            sx={{
              color: accent800,
              '&:hover': {
                color: accent900,
              },
            }}
          >
            Log in
          </Button>
          <Button
            onClick={() => {
              history.push('register');
              history.go(0);
            }}
            sx={{
              color: accent800,
              '&:hover': {
                color: accent900,
              },
            }}
          >
            Register
          </Button>
        </Box>
      </Container>
    </Box>
  );
}
