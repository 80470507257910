// Components
import { Box, Button, Container, Typography } from '@mui/material';
import Footer from '../components/footer';

// Utilities
import { IsDesktop } from '../utilities/mediaQuery';

// Colors
import { grey } from '@mui/material/colors';

// Icons
import ErrorIcon from '@mui/icons-material/Error';
import { accent800, accent900 } from '../utilities/colors';
import { history } from '../utilities/history';

export default function PageNotFound() {
  // STYLES
  // Generic
  var isDesktop = IsDesktop();
  // Specific
  const mainContainer = {
    px: isDesktop ? '15px' : 0,
    gap: '15px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  };

  // VARIABLES
  return (
    <Box sx={mainContainer}>
      <Box
        sx={{
          background: grey[300],
          borderBottomLeftRadius: '30px',
          borderBottomRightRadius: '30px',
          boxShadow: `0 1px 2px ${grey[900]}`,
        }}
      >
        <Container
          sx={{
            p: '15px',
            background: grey[100],
            borderBottomLeftRadius: '30px',
            borderBottomRightRadius: '30px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <ErrorIcon sx={{ fontSize: '3rem', color: accent900 }} />
            <Box sx={{ ml: '15px' }}>
              <Typography variant="h5" sx={{ color: accent900 }}>
                Error
              </Typography>
              <Typography variant="caption" sx={{ color: grey[700] }}>
                Something went wrong.
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          p: isDesktop ? '15px' : 0,
          background: grey[300],
          borderRadius: '5px',
          flexGrow: 1,
          display: 'flex',
        }}
      >
        <Container
          sx={{
            p: '15px',
            minHeight: '50vh',
            background: grey[200],
            borderRadius: '5px',
            gap: '15px',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography variant="h3" sx={{ color: grey[600] }}>
              Error 404
            </Typography>
            <Typography variant="body2" sx={{ color: grey[500] }}>
              Page not found
            </Typography>
          </Box>
          <Button
            onClick={() => {
              history.push('home');
              history.go(0);
            }}
            sx={{
              color: accent800,
              '&:hover': {
                color: accent900,
              },
            }}
          >
            Go back
          </Button>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
}
