import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyA3WslzPkjhy_HMCj4wqc7fGKKxiwRxFyA',
  authDomain: 'wasterapp-9ea2a.firebaseapp.com',
  databaseURL: 'https://wasterapp-9ea2a-default-rtdb.firebaseio.com',
  projectId: 'wasterapp-9ea2a',
  storageBucket: 'wasterapp-9ea2a.appspot.com',
  messagingSenderId: '998020912330',
  appId: '1:998020912330:web:df3a91d3642dd1d734d033',
  measurementId: 'G-E5B3B75ZGT',
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const storage = getStorage(app);

export const actionCodeSettings = {
  url: `https://waster.app/`,
  // url: `https://wasterapp-stagingdev.web.app/`,
  // url: `https://wasterapp-9ea2a.web.app/`,
  // url: `http://localhost:3000/`,
  handleCodeInApp: true,
};
