import { Box, IconButton } from '@mui/material';
import { grey } from '@mui/material/colors';
import { IsDesktop } from '../utilities/mediaQuery';

import CloseIcon from '@mui/icons-material/Close';
export const notifs = [];

export default function Notifications() {
  // STYLES
  // Generic
  var isDesktop = IsDesktop();
  return (
    <Box
      id="notifications"
      sx={{
        maxWidth: isDesktop ? '30vw' : '100vw',
        top: '',
        right: '30px',
        p: '15px',
        gap: '15px',
        zIndex: 2,
        display: 'flex',
        flexDirection: 'column',
        position: 'fixed',
        // transform: 'translateY(50%)',
        transition: 'all 0.15s ease',
      }}
    >
      {notifs.length > 0 &&
        notifs.map((notif, index) => (
          <Box
            id={`notif/${notif.type}/${index}`}
            sx={{
              p: '15px',
              gap: '15px',
              background: notif.background,
              color: grey[50],
              borderRadius: '5px',
              boxShadow: `0 4px 8px -4px ${grey[900]}`,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              transition: 'all 0.3s ease',
            }}
          >
            {notif.content}
            <IconButton
              size="small"
              color="inherit"
              onClick={() => notifs.splice(index, 1)}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        ))}
    </Box>
  );
}
