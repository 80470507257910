// Components
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';

// Utilities
import { IsDesktop } from '../utilities/mediaQuery';
import moment from 'moment-timezone';
import { auth } from '../utilities/firebaseController';
import { useAuthState } from 'react-firebase-hooks/auth';
import emailjs from '@emailjs/browser';
import { WasteTypes } from '../utilities/wasteTypes';
import { keyEmailJs } from '../utilities/emailjsController';
import { notifs } from './notifications';

// Colors
import { green, grey, red } from '@mui/material/colors';
import {
  accent900,
  accent800,
  primary500,
  primary900,
} from '../utilities/colors';

// Icons
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import RequestIcon from '@mui/icons-material/AddCircle';
import ReportIcon from '@mui/icons-material/ReportProblem';
import { DateRangeSharp } from '@mui/icons-material';
import { RouteCycles } from '../utilities/routeCycles';

export default function ServiceModal(props) {
  // STYLES
  // Generic
  var isDesktop = IsDesktop();
  // Specific
  // VARIABLES
  const {
    modal,
    reqSchedule,
    reqState,
    setReqState,
    details,
    services,
    schedules,
    reqService,
  } = props;
  const [modalType, setModalType] = useState(0);
  const [user, loading, error] = useAuthState(auth);
  const [customerCompany, setCustomerCompany] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');

  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  var dates = [];
  const today = moment().format('L');
  const tomorrow = moment().add(1, 'days').format('L');

  let selectedServiceDateTodayTemp = moment().format('YYYYMMDD');
  const [selectedServiceDateTemp, setSelectedServiceDateTemp] = useState(
    moment().format('YYYYMMDD')
  );
  const [selectedServiceDateError, setSelectedServiceDateError] =
    useState(false);
  const [selectedReportDateError, setSelectedReportDateError] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(moment(tomorrow).month());
  const [selectedDate, setSelectedDate] = useState(moment(tomorrow).date());
  const [selectedYear, setSelectedYear] = useState(moment(tomorrow).year());
  const [selectedService, setSelectedService] = useState('');
  const [additionalNotes, setAdditionalNotes] = useState('');

  const [nextServiceDate, setNextServiceDate] = useState(null);
  const [prevServiceDate, setPrevServiceDate] = useState(null);

  const [datesComponent, setDatesComponent] = useState(null);

  const [requesting, setRequesting] = useState(false);

  // FUNCTIONS
  function getDaysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }
  const selectedServiceChange = (e) => {
    setSelectedService(e.target.value);
  };
  const additionalNotesChange = (e) => {
    setAdditionalNotes(e.target.value);
  };
  const requestExtraService = (e) => {
    e.preventDefault();
    setRequesting(true);
    emailjs
      .sendForm('wasterapp', 'request_service', e.target, keyEmailJs)
      .then(() => {
        notifs.push({
          type: 1,
          background: green[500],
          content: (
            <Box>
              <Typography
                variant="body2"
                sx={{ '&>span': { fontWeight: 500 } }}
              >
                Request for extra service sent!
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  '&>span': {
                    fontWeight: 500,
                  },
                }}
              >
                We sent a copy to your email.
                <br />
                Please check your spam/junk folder.
              </Typography>
            </Box>
          ),
        });
        console.log('Contact ticket sent successfully!');
        setRequesting(false);
      })
      .catch((err) => {
        notifs.push({
          type: 1,
          background: red[500],
          content: (
            <Box>
              <Typography
                variant="body2"
                sx={{ '&>span': { fontWeight: 500 } }}
              >
                Failed to send request for extra service.
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  '&>span': {
                    fontWeight: 500,
                  },
                }}
              >
                Email us directly at <span>app@waster.com.au</span>.
              </Typography>
            </Box>
          ),
        });
        console.log('Contact ticket sent successfully!');
        console.log(err);
        setRequesting(false);
      });
  };
  const reportMissedService = (e) => {
    e.preventDefault();
    setRequesting(true);
    emailjs
      .sendForm('wasterapp', 'report_missed_service', e.target, keyEmailJs)
      .then(() => {
        notifs.push({
          type: 1,
          background: green[500],
          content: (
            <Box>
              <Typography
                variant="body2"
                sx={{ '&>span': { fontWeight: 500 } }}
              >
                Report for missed service sent! We will look into it.
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  '&>span': {
                    fontWeight: 500,
                  },
                }}
              >
                We sent a copy to your email.
                <br />
                Please check your spam/junk folder.
              </Typography>
            </Box>
          ),
        });
        console.log('Contact ticket sent successfully!');
        setRequesting(false);
      })
      .catch((err) => {
        notifs.push({
          type: 1,
          background: red[500],
          content: (
            <Box>
              <Typography
                variant="body2"
                sx={{ '&>span': { fontWeight: 500 } }}
              >
                Failed to send report for missed service.
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  '&>span': {
                    fontWeight: 500,
                  },
                }}
              >
                Email us directly at <span>app@waster.com.au</span>.
              </Typography>
            </Box>
          ),
        });
        console.log('Contact ticket sent successfully!');
        console.log(err);
        setRequesting(false);
      });
  };

  // EFFECTS
  useEffect(() => {
    dates.splice(0, dates.length);
    var i = 1;
    while (i <= getDaysInMonth(selectedMonth, selectedYear)) {
      dates.push(i);
      i++;
    }
    setDatesComponent(
      dates.map((date) => (
        <MenuItem key={date} value={date}>
          {date}
        </MenuItem>
      ))
    );
  }, [selectedMonth, selectedYear]);

  useEffect(() => {
    if (details) {
      setCustomerCompany(details[0].name);
    }
  }, [details]);

  useEffect(() => {
    if (user) {
      setCustomerEmail(user.email);
    }
  }, [user]);

  useEffect(() => {
    if (services && schedules) {
      setSelectedService(services[0].waste_type);
    }
  }, [services, schedules]);

  useEffect(() => {
    if (selectedService) {
      var nextServiceDateTemp = [];
      var prevServiceDateTemp = [];
      services.map((service) => {
        if (service.waste_type === selectedService) {
          schedules.map((schedule) => {
            if (schedule.line_no === service.$id) {
              if (schedule.service === service.$id) {
                nextServiceDateTemp.push(schedule.next_service_date);
                prevServiceDateTemp.push(
                  moment(schedule.next_service_date, 'YYYY-MM-DD')
                    .subtract(
                      RouteCycles(service.route_cycle).forEvery,
                      RouteCycles(service.route_cycle).unit
                    )
                    .format('YYYY-MM-DD')
                );
              }
            }
          });
        }
      });
      setNextServiceDate(nextServiceDateTemp);
      setPrevServiceDate(prevServiceDateTemp);
    }
  }, [selectedService]);

  useEffect(() => {
    if (reqSchedule) {
      setSelectedMonth(moment(reqSchedule).month() + 1);
      setSelectedDate(moment(reqSchedule).date());
      setSelectedYear(moment(reqSchedule).year());
    }
  }, [reqSchedule]);

  useEffect(() => {
    if (modal !== null) {
      setModalType(modal);
    }
  }, [modal]);

  useEffect(() => {
    if (reqState) {
      setReqState(reqState);
    }
  }, [reqState]);

  useEffect(() => {
    if (reqService) {
      setSelectedService(reqService);
    }
  }, [reqService]);

  useEffect(() => {
    setSelectedServiceDateTemp(
      `${moment(selectedYear, 'YYYY').format('YYYY')}${moment(
        selectedMonth,
        'M'
      ).format('MM')}${moment(selectedDate, 'D').format('DD')}`
    );
    if (selectedServiceDateTemp >= selectedServiceDateTodayTemp) {
      setSelectedReportDateError(true);
    } else {
      setSelectedReportDateError(false);
    }
    if (selectedServiceDateTemp <= selectedServiceDateTodayTemp) {
      setSelectedServiceDateError(true);
    } else if (
      selectedServiceDateTemp === moment(tomorrow).format('YYYYMMDD') &&
      moment().tz('Australia/Sydney').format('a') === 'pm'
    ) {
      console.log('Booking for tomorrow');
      setSelectedServiceDateError(true);
    } else {
      setSelectedServiceDateError(false);
    }
  });

  return (
    <Box
      sx={{
        zIndex: 2,
        p: '15px',
        right: 0,
        left: 0,
        top: 0,
        bottom: 0,
        gap: '15px',
        background: `rgba(0,0,0,0.35)`,
        position: `fixed`,
        transition: `all 0.15s ease`,
        transform: reqState ? `translateX(0)` : `translateX(120%)`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          maxWidth: '70vw',
          p: '30px',
          gap: '30px',
          width: isDesktop ? '50vw' : null,
          background: grey[300],
          borderRadius: '5px',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
        }}
      >
        <Box
          sx={{
            color: grey[600],
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5">Waster Service Form</Typography>
          <IconButton
            onClick={() => {
              setReqState(false);
            }}
            color="inherit"
            size="small"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box>
          <Box
            sx={{
              mb: '-2px',
              background: grey[300],
              display: 'flex',
              zIndex: 2,
              '& .MuiButton-root': {
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                boxSizing: 'border-box',
              },
              '&>:first-child': {
                background: modalType === 0 ? grey[200] : null,
                boxShadow:
                  modalType === 0
                    ? `0 12px 12px -16px ${grey[900]}, inset 2px 0 0 ${primary500}, inset 0 2px 0 ${primary500}, inset -2px 0 0 ${primary500}`
                    : null,
              },
              '&>:last-child': {
                background: modalType === 1 ? grey[200] : null,
                boxShadow:
                  modalType === 1
                    ? `0 12px 12px -16px ${grey[900]}, inset 2px 0 0 ${accent800}, inset 0 2px 0 ${accent800}, inset -2px 0 0 ${accent800}`
                    : null,
              },
            }}
          >
            <Button
              onClick={() => {
                setModalType(0);
              }}
              fullWidth
              startIcon={isDesktop ? <RequestIcon /> : null}
              sx={{ color: primary900 }}
            >
              Request Extra Service
            </Button>
            <Button
              onClick={() => {
                setModalType(1);
              }}
              fullWidth
              startIcon={isDesktop ? <ReportIcon /> : null}
              sx={{
                color: accent800,
              }}
            >
              Report Missed Service
            </Button>
          </Box>
          <Box
            sx={{
              background: grey[200],
              borderTopLeftRadius: modalType === 0 ? 0 : '5px',
              borderTopRightRadius: modalType === 1 ? 0 : '5px',
              borderBottomLeftRadius: '5px',
              borderBottomRightRadius: '5px',
              borderColor: modalType === 0 ? primary500 : accent800,
              borderWidth: '2px',
              borderStyle: 'solid',
              boxSizing: 'border-box',
            }}
          >
            {modalType === 0 && (
              <Box
                component="form"
                onSubmit={requestExtraService}
                sx={{
                  minHeight: '60vh',
                  maxHeight: '60vh',
                  p: '15px',
                  gap: '15px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  overflow: 'auto',
                }}
              >
                <Box>
                  {details && (
                    <Box
                      sx={{
                        pt: '15px',
                        px: '15px',
                        gap: '15px',
                        display: 'flex',
                        flexDirection: 'column',
                        '& .MuiTextField-root': {
                          background: grey[50],
                        },
                      }}
                    >
                      <Typography sx={{ fontWeight: 500, color: grey[700] }}>
                        Customer Details:
                      </Typography>
                      <TextField
                        select
                        required
                        id="customerCompany"
                        name="customerCompany"
                        label="Company"
                        value={customerCompany}
                        fullWidth
                        size="small"
                      >
                        {details.map((detail) => (
                          <MenuItem key={detail.name} value={detail.name}>
                            {detail.name}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        required
                        id="customerEmail"
                        name="customerEmail"
                        label="Email"
                        value={customerEmail}
                        fullWidth
                        size="small"
                      />
                    </Box>
                  )}
                  {services && (
                    <Box
                      sx={{
                        pt: '15px',
                        px: '15px',
                        gap: '15px',
                        display: 'flex',
                        flexDirection: 'column',
                        '& .MuiTextField-root': {
                          background: grey[50],
                        },
                      }}
                    >
                      <Typography sx={{ fontWeight: 500, color: grey[700] }}>
                        Request Pick-up Service Details:
                      </Typography>

                      <Box sx={{ pb: '5px', color: grey[600] }}>
                        <Typography variant="body2">
                          Next Pick-up Schedule for selected service:
                        </Typography>
                        {nextServiceDate &&
                          nextServiceDate.map((date, index) => (
                            <Typography key={index} sx={{ fontWeight: 500 }}>
                              {moment(date, 'YYYY-MM-DD').format('LL')} (
                              {moment(
                                `${date}-${moment().format('HH-mm-ss')}`,
                                'YYYY-MM-DD-HH-mm-ss'
                              ).fromNow()}
                              )
                            </Typography>
                          ))}
                      </Box>

                      <TextField
                        select
                        required
                        id="scheduleServiceType"
                        name="scheduleServiceType"
                        label="Service Type"
                        value={selectedService}
                        onChange={selectedServiceChange}
                        fullWidth
                        size="small"
                      >
                        {services.map((service) => {
                          if (
                            service.waste_type !== 'FUT' &&
                            service.waste_type !== 'FUT1' &&
                            service.waste_type !== 'FUT2' &&
                            service.waste_type !== 'FUT3' &&
                            service.waste_type !== 'FUT4'
                          ) {
                            return (
                              <MenuItem
                                key={service.waste_type}
                                value={service.waste_type}
                              >
                                {WasteTypes(service.waste_type).name}
                              </MenuItem>
                            );
                          }
                        })}
                      </TextField>
                      <Box
                        sx={{
                          gap: '15px',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Box
                          sx={{
                            gap: '15px',
                            display: 'flex',
                            flexDirection: isDesktop ? 'row' : 'column',
                          }}
                        >
                          <TextField
                            select
                            required
                            id="scheduleServiceMonth"
                            name="scheduleServiceMonth"
                            onChange={(e) => {
                              setSelectedMonth(e.target.value);
                            }}
                            label="Month"
                            value={selectedMonth}
                            fullWidth
                            size="small"
                            error={selectedServiceDateError}
                          >
                            {months.map((month) => (
                              <MenuItem key={month} value={month}>
                                {moment(month, 'MM').format('MMMM')}
                              </MenuItem>
                            ))}
                          </TextField>
                          <TextField
                            select
                            required
                            id="scheduleServiceDay"
                            name="scheduleServiceDay"
                            onChange={(e) => {
                              setSelectedDate(e.target.value);
                            }}
                            label="Date"
                            value={selectedDate}
                            fullWidth
                            size="small"
                            error={selectedServiceDateError}
                          >
                            {datesComponent}
                          </TextField>
                          <TextField
                            required
                            id="scheduleServiceYear"
                            name="scheduleServiceYear"
                            onChange={(e) => {
                              setSelectedYear(e.target.value);
                            }}
                            type="number"
                            label="Year"
                            value={selectedYear}
                            fullWidth
                            size="small"
                            error={selectedServiceDateError}
                          />
                        </Box>
                        {selectedServiceDateError && (
                          <Typography
                            variant="caption"
                            sx={{
                              color: accent800,
                              '&>span': {
                                fontWeight: 500,
                              },
                            }}
                          >
                            <span>Invalid Date</span>: Cannot request service
                            for past dates or today. If booking for tomorrow,
                            cannot request service on or after 12:00 NN.
                          </Typography>
                        )}
                      </Box>
                      <TextField
                        multiline
                        rows={3}
                        id="scheduleServiceNotes"
                        name="scheduleServiceNotes"
                        onChange={additionalNotesChange}
                        label="Additional notes"
                        value={additionalNotes}
                        fullWidth
                        size="small"
                      />
                      <TextField
                        id="messageSentDate"
                        name="messageSentDate"
                        value={moment().format('MMMM D, YYYY')}
                        sx={{ display: 'none' }}
                      />
                      <TextField
                        id="messageSentTime"
                        name="messageSentTime"
                        value={moment().format('h:mm a')}
                        sx={{ display: 'none' }}
                      />
                    </Box>
                  )}
                  <Box
                    sx={{
                      pt: '15px',
                      px: '15px',
                      gap: '15px',
                      display: 'flex',
                    }}
                  >
                    <InfoIcon sx={{ color: primary500 }} />
                    <Box>
                      <Typography sx={{ color: grey[700], fontWeight: 500 }}>
                        Note:
                      </Typography>
                      <Typography variant="body2" sx={{ color: grey[700] }}>
                        To request extra pick-up or modify/cancel existing
                        schedules, please enter request below. We will book
                        extra ring ins for the next available day (this may not
                        be tomorrow - as truck may not be in your area - which
                        we will confirm via email). We can cancel scheduled
                        services at no cost if informed by 12:00 PM (noon) the
                        day prior.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    pt: '15px',
                    gap: '15px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    onClick={() => {
                      setReqState(false);
                    }}
                    sx={{
                      color: primary500,
                      '&:hover': {
                        color: primary900,
                      },
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    disabled={requesting || selectedServiceDateError}
                    sx={{
                      background: primary500,
                      color: grey[50],
                      '&:hover': {
                        background: primary900,
                      },
                      '&.Mui-disabled': {
                        background: grey[400],
                        color: grey[500],
                      },
                    }}
                  >
                    {requesting ? (
                      <CircularProgress sx={{ color: grey[500] }} size={24} />
                    ) : (
                      'Submit Request'
                    )}
                  </Button>
                </Box>
              </Box>
            )}
            {modalType === 1 && (
              <Box
                component="form"
                onSubmit={reportMissedService}
                sx={{
                  minHeight: '60vh',
                  maxHeight: '60vh',
                  p: '15px',
                  gap: '15px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  overflow: 'auto',
                }}
              >
                <Box>
                  {details && (
                    <Box
                      sx={{
                        pt: '15px',
                        px: '15px',
                        gap: '15px',
                        display: 'flex',
                        flexDirection: 'column',
                        '& .MuiTextField-root': {
                          background: grey[50],
                        },
                      }}
                    >
                      <Typography sx={{ fontWeight: 500, color: grey[700] }}>
                        Customer Details:
                      </Typography>
                      <TextField
                        select
                        required
                        id="customerCompany"
                        name="customerCompany"
                        label="Company"
                        value={customerCompany}
                        fullWidth
                        size="small"
                      >
                        {details.map((detail) => (
                          <MenuItem key={detail.name} value={detail.name}>
                            {detail.name}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        required
                        id="customerEmail"
                        name="customerEmail"
                        label="Email"
                        value={customerEmail}
                        fullWidth
                        size="small"
                      />
                    </Box>
                  )}
                  {services && (
                    <Box
                      sx={{
                        pt: '15px',
                        px: '15px',
                        gap: '15px',
                        display: 'flex',
                        flexDirection: 'column',
                        '& .MuiTextField-root': {
                          background: grey[50],
                        },
                      }}
                    >
                      <Typography sx={{ fontWeight: 500, color: accent800 }}>
                        Report Missed Service Details:
                      </Typography>

                      <Box sx={{ pb: '5px', color: grey[600] }}>
                        <Typography variant="body2">
                          Previous pick-up schedule for selected service:
                        </Typography>
                        {prevServiceDate &&
                          prevServiceDate.map((date, index) => (
                            <Typography key={index} sx={{ fontWeight: 500 }}>
                              {moment(date, 'YYYY-MM-DD').format('LL')} (
                              {moment(
                                `${date}-${moment().format('HH-mm-ss')}`,
                                'YYYY-MM-DD-HH-mm-ss'
                              ).fromNow()}
                              )
                            </Typography>
                          ))}
                      </Box>
                      <Box
                        sx={{
                          gap: '15px',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <TextField
                          select
                          required
                          id="reportServiceType"
                          name="reportServiceType"
                          label="Service Type"
                          value={selectedService}
                          onChange={selectedServiceChange}
                          fullWidth
                          size="small"
                        >
                          {services.map((service) => {
                            if (
                              service.waste_type !== 'FUT' &&
                              service.waste_type !== 'FUT1' &&
                              service.waste_type !== 'FUT2' &&
                              service.waste_type !== 'FUT3' &&
                              service.waste_type !== 'FUT4'
                            ) {
                              return (
                                <MenuItem
                                  key={service.waste_type}
                                  value={service.waste_type}
                                >
                                  {WasteTypes(service.waste_type).name}
                                </MenuItem>
                              );
                            }
                          })}
                        </TextField>
                        <Box
                          sx={{
                            gap: '15px',
                            display: 'flex',
                            flexDirection: isDesktop ? 'row' : 'column',
                          }}
                        >
                          <TextField
                            select
                            required
                            id="reportServiceMonth"
                            name="reportServiceMonth"
                            onChange={(e) => {
                              setSelectedMonth(e.target.value);
                            }}
                            label="Month"
                            value={selectedMonth}
                            fullWidth
                            size="small"
                            error={selectedReportDateError}
                          >
                            {months.map((month) => (
                              <MenuItem key={month} value={month}>
                                {moment(month, 'MM').format('MMMM')}
                              </MenuItem>
                            ))}
                          </TextField>
                          <TextField
                            select
                            required
                            id="reportServiceDay"
                            name="reportServiceDay"
                            onChange={(e) => {
                              setSelectedDate(e.target.value);
                            }}
                            label="Date"
                            value={selectedDate}
                            fullWidth
                            size="small"
                            error={selectedReportDateError}
                          >
                            {datesComponent}
                          </TextField>
                          <TextField
                            required
                            id="reportServiceYear"
                            name="reportServiceYear"
                            onChange={(e) => {
                              setSelectedYear(e.target.value);
                            }}
                            type="number"
                            label="Year"
                            value={selectedYear}
                            fullWidth
                            size="small"
                            error={selectedReportDateError}
                          />
                        </Box>
                        {selectedReportDateError && (
                          <Typography
                            variant="caption"
                            sx={{
                              color: accent800,
                              '&>span': {
                                fontWeight: 500,
                              },
                            }}
                          >
                            <span>Invalid Date</span>: Cannot report missed
                            service for future dates or today.
                          </Typography>
                        )}
                      </Box>
                      <TextField
                        multiline
                        rows={3}
                        id="reportServiceNotes"
                        name="reportServiceNotes"
                        onChange={additionalNotesChange}
                        label="Additional notes"
                        value={additionalNotes}
                        fullWidth
                        size="small"
                      />
                      <TextField
                        id="messageSentDate"
                        name="messageSentDate"
                        value={moment().format('MMMM D, YYYY')}
                        sx={{ display: 'none' }}
                      />
                      <TextField
                        id="messageSentTime"
                        name="messageSentTime"
                        value={moment().format('h:mm a')}
                        sx={{ display: 'none' }}
                      />
                    </Box>
                  )}
                </Box>
                <Box
                  sx={{
                    pt: '15px',
                    gap: '15px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    onClick={() => {
                      setReqState(false);
                    }}
                    sx={{
                      color: accent800,
                      '&:hover': {
                        color: accent900,
                      },
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    disabled={requesting || selectedReportDateError}
                    sx={{
                      background: accent800,
                      color: grey[50],
                      '&:hover': {
                        background: accent900,
                      },
                      '&.Mui-disabled': {
                        background: grey[400],
                        color: grey[500],
                      },
                    }}
                  >
                    {requesting ? (
                      <CircularProgress sx={{ color: grey[500] }} size={24} />
                    ) : (
                      'Submit Report'
                    )}
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
