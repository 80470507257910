// Components
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Collapse,
  Container,
  Divider,
  Link,
  Typography,
} from '@mui/material';
import Calendar from '../components/calendar';
import Footer from '../components/footer';
import ServiceModal from '../components/serviceModal';
import Unverified from '../components/unverified';
import Unregistered from '../components/unregistered';

// Utilities
import { IsDesktop } from '../utilities/mediaQuery';
import { actionCodeSettings, auth } from '../utilities/firebaseController';
import { useAuthState } from 'react-firebase-hooks/auth';
import moment from 'moment';
import MediaQuery from 'react-responsive';
import axios from 'axios';
import { WasteTypes } from '../utilities/wasteTypes';
import { RouteCycles } from '../utilities/routeCycles';
import { history } from '../utilities/history';
// Colors
import { grey } from '@mui/material/colors';
import {
  accent900,
  accent800,
  secondary500,
  secondary900,
} from '../utilities/colors';

// Icons
import DashboardIcon from '@mui/icons-material/Dashboard';
import MoreIcon from '@mui/icons-material/ExpandMore';
import LessIcon from '@mui/icons-material/ExpandLess';
import ReportIcon from '@mui/icons-material/EventBusy';
import CancelServiceIcon from '@mui/icons-material/RemoveCircle';
import RequestIcon from '@mui/icons-material/AddCircle';
import ContactUsIcon from '@mui/icons-material/SupportAgent';

// Global Functions
// Default export
export default function Dashboard() {
  // STYLES
  // Generic
  var isDesktop = IsDesktop();
  // Specific
  const mainContainer = {
    px: isDesktop ? `15px` : 0,
    gap: `15px`,
    flexGrow: 1,
    display: `flex`,
    flexDirection: `column`,
    position: `relative`,
  };

  // VARIABLES
  const [user, loading, error] = useAuthState(auth);
  const [userVerified, setUserVerified] = useState(null);
  const [wastedgeDetails, setWastedgeDetails] = useState(null);
  const [wastedgeServices, setWastedgeServices] = useState(null);
  const [wastedgeSchedules, setWastedgeSchedules] = useState(null);

  const [accounts, setAccounts] = useState([]);
  const [accountChosen, setAccountChosen] = useState(null);
  const [accountsPaneState, setAccountsPaneState] = useState(false);
  const [noServices, setNoServices] = useState(false);

  const [modalType, setModalType] = useState(0);
  const [reqState, setReqState] = useState(false);
  const [reqSchedule, setReqSchedule] = useState(null);

  // FUNCTIONS
  const openModal = (scheduleDate) => {
    setReqState(true);
    setReqSchedule(scheduleDate);
  };
  const getDetails = (email) => {
    axios
      .get(
        `https://us-central1-wasterapp-9ea2a.cloudfunctions.net/wastedge/getDetails/${email}`
      )
      .then((response) => {
        setWastedgeDetails(response.data.result);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 500) {
          setTimeout(() => {
            getDetails(email);
          }, 6000);
        }
      });
  };
  const getServices = (id) => {
    axios
      .get(
        `https://us-central1-wasterapp-9ea2a.cloudfunctions.net/wastedge/getServices/${id}`
      )
      .then((response) => {
        setWastedgeServices(response.data.result);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 500) {
          setTimeout(() => {
            getServices(id);
          }, 6000);
        }
      });
  };
  const getSchedules = (id) => {
    axios
      .get(
        `https://us-central1-wasterapp-9ea2a.cloudfunctions.net/wastedge/getSchedules/${id}`
      )
      .then((response) => {
        setWastedgeSchedules(response.data.result);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 500) {
          setTimeout(() => {
            getSchedules(id);
          }, 6000);
        }
      });
  };

  // EFFECTS
  useEffect(() => {
    if (user) {
      setUserVerified(user.emailVerified);
    }
  }, [user, loading, error]);
  useEffect(() => {
    if (userVerified) {
      getDetails(user.email);
    }
  }, [userVerified]);

  useEffect(() => {
    if (wastedgeDetails) {
      let accountsTemp = [];
      if (wastedgeDetails.length < 1) {
        setNoServices(true);
      } else {
        wastedgeDetails.map((wastedgeDetail) =>
          accountsTemp.push(wastedgeDetail)
        );
        setAccounts(accountsTemp);
      }
    }
  }, [wastedgeDetails]);

  useEffect(() => {
    if (accounts.length > 0) {
      setAccountChosen(accounts[0]);
    }
  }, [accounts]);

  useEffect(() => {
    if (accountChosen) {
      setWastedgeServices(null);
      setWastedgeSchedules(null);
      getServices(accountChosen.$id);
      getSchedules(accountChosen.$id);
    }
  }, [accountChosen]);

  return (
    <Box sx={mainContainer}>
      <Box
        sx={{
          background: grey[300],
          borderBottomLeftRadius: `30px`,
          borderBottomRightRadius: `30px`,
          boxShadow: `0 1px 2px ${grey[900]}`,
        }}
      >
        <Container
          sx={{
            p: `15px`,
            background: grey[100],
            borderBottomLeftRadius: `30px`,
            borderBottomRightRadius: `30px`,
            display: `flex`,
            justifyContent: `space-between`,
          }}
        >
          <Box
            sx={{
              display: `flex`,
              alignItems: `center`,
            }}
          >
            <DashboardIcon sx={{ fontSize: `3rem`, color: accent900 }} />
            <Box sx={{ ml: `15px` }}>
              <Typography variant="h5" sx={{ color: accent900 }}>
                Dashboard
              </Typography>
              <MediaQuery maxWidth={479}>
                <Typography variant="caption" sx={{ color: grey[700] }}>
                  One-stop information page.
                </Typography>
              </MediaQuery>
              <MediaQuery minWidth={480}>
                <Typography variant="caption" sx={{ color: grey[700] }}>
                  One-stop information page for your Waster services and
                  schedules.
                </Typography>
              </MediaQuery>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          background: userVerified !== null ? grey[300] : null,
          borderRadius: '5px',
        }}
      >
        {!user && !loading && !error && <Unregistered />}
        {userVerified === false && <Unverified />}
        {userVerified && (
          <Box
            sx={{
              p: isDesktop ? `15px` : 0,
              backgroundColor: grey[300],
              borderRadius: `5px`,
              boxShadow: `0 1px 2px ${grey[900]}`,
              flexGrow: 1,
              display: `flex`,
            }}
          >
            <Container
              sx={{
                p: isDesktop ? `30px` : `10px`,
                gap: `15px`,
                background: grey[200],
                borderRadius: `5px`,
                flexGrow: 1,
                display: `flex`,
                flexDirection: `column`,
              }}
            >
              {!accountChosen && !noServices && (
                <Box
                  sx={{
                    minHeight: '30vh',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: grey[400],
                  }}
                >
                  <Typography variant="h4">Loading...</Typography>
                </Box>
              )}
              {noServices && (
                <Box
                  sx={{
                    gap: '30px',
                    minHeight: '30vh',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: grey[600],
                  }}
                >
                  <Typography variant="h4">
                    Your email is not found in any Waster services.
                  </Typography>
                  <Typography
                    variant="body2"
                    align="center"
                    sx={{ color: accent900 }}
                  >
                    Please make sure that you are using the same email you
                    registered for your Waster services.
                    <br></br>
                    If you need assistance with this, please&nbsp;
                    <Link
                      onClick={() => {
                        history.push('/contact');
                        history.go(0);
                      }}
                    >
                      contact us
                    </Link>
                    .
                  </Typography>
                </Box>
              )}
              {accounts.length > 1 && accountChosen && (
                <Box
                  sx={{
                    background: secondary500,
                    color: grey[50],
                    borderRadius: `5px`,
                    boxShadow: `0 1px 2px ${grey[900]}`,
                  }}
                >
                  <Box
                    sx={{
                      p: `15px`,
                      display: `flex`,
                      justifyContent: `space-between`,
                      alignItems: `flex-end`,
                    }}
                  >
                    <Box>
                      <Typography variant="caption">
                        Current account:
                      </Typography>
                      <Typography variant="h5">{accountChosen.name}</Typography>
                    </Box>
                    <Button
                      sx={{
                        flexShrink: 0,
                        color: grey[50],
                        '&:hover': {
                          color: grey[100],
                        },
                      }}
                      endIcon={accountsPaneState ? <LessIcon /> : <MoreIcon />}
                      onClick={() => {
                        setAccountsPaneState(!accountsPaneState);
                      }}
                      size="small"
                    >
                      {isDesktop ? 'Choose Account' : null}
                    </Button>
                  </Box>
                  <Collapse in={accountsPaneState}>
                    <Box
                      sx={{
                        background: secondary900,
                        borderRadius: `5px`,
                      }}
                    >
                      {accounts.map((account, index) => (
                        <Box key={index}>
                          <Typography
                            sx={{
                              p: `15px`,
                            }}
                            onClick={() => {
                              setAccountChosen(accounts[index]);
                              setAccountsPaneState(false);
                            }}
                          >
                            {account.name}
                          </Typography>
                          {index < accounts.length - 1 ? (
                            <Divider sx={{ borderColor: grey[500] }} />
                          ) : null}
                        </Box>
                      ))}
                    </Box>
                  </Collapse>
                </Box>
              )}

              {accountChosen && (
                <Box
                  sx={{
                    p: isDesktop ? `30px` : `10px`,
                    background: grey[100],
                    borderRadius: `5px`,
                    boxShadow: `0 1px 2px ${grey[900]}`,
                    flexShrink: 0,
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {wastedgeServices && wastedgeSchedules && (
                    <Calendar
                      openModal={openModal}
                      services={wastedgeServices}
                      schedules={wastedgeSchedules}
                    />
                  )}
                  <Collapse in={!wastedgeServices && !wastedgeSchedules}>
                    <Typography
                      variant="h5"
                      sx={{
                        flexGrow: 1,
                        color: grey[400],
                        textAlign: 'center',
                      }}
                    >
                      Loading your calendar...
                    </Typography>
                  </Collapse>
                </Box>
              )}
              {accountChosen && (
                <Box
                  sx={{
                    p: isDesktop ? `30px` : `15px`,
                    gap: '15px',
                    display: 'flex',
                    flexDirection: 'column',
                    background: grey[100],
                    borderRadius: `5px`,
                    boxShadow: `0 1px 2px ${grey[900]}`,
                    flexShrink: 0,
                  }}
                >
                  <Box>
                    <Typography>Pick-up Schedules</Typography>
                    <Typography variant="caption">
                      Next pick-up schedules for your Waster Services.
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      gap: '15px',
                      display: 'flex',
                      flexFlow: 'row wrap',
                      '&> .MuiButton-root': {
                        flexGrow: 1,
                      },
                    }}
                  >
                    {/* <Button
                      onClick={() => {
                        setModalType(1);
                        setReqState(true);
                        setReqSchedule(
                          moment().subtract(1, 'days').format('L')
                        );
                      }}
                      startIcon={<CancelServiceIcon />}
                      sx={{
                        borderStyle: 'solid',
                        borderWidth: '2px',
                        borderColor: accent800,
                        color: accent800,
                        '&:hover': {
                          color: accent900,
                        },
                      }}
                    >
                      Cancel A Service
                    </Button> */}
                    <Button
                      onClick={() => {
                        setModalType(2);
                        setReqState(true);
                        setReqSchedule(
                          moment().subtract(1, 'days').format('L')
                        );
                      }}
                      startIcon={<ReportIcon />}
                      sx={{
                        borderStyle: 'solid',
                        borderWidth: '2px',
                        borderColor: accent800,
                        color: accent800,
                        '&:hover': {
                          color: accent900,
                        },
                      }}
                    >
                      Report Missed Service
                    </Button>
                    <Button
                      onClick={() => {
                        history.push('/contact');
                        history.go(0);
                      }}
                      startIcon={<ContactUsIcon />}
                      sx={{
                        borderStyle: 'solid',
                        borderWidth: '2px',
                        borderColor: accent800,
                        color: accent800,
                        '&:hover': {
                          color: accent900,
                        },
                      }}
                    >
                      Report Other Concerns
                    </Button>
                    <Button
                      onClick={() => {
                        setModalType(0);
                        setReqState(true);
                        setReqSchedule(moment().add(1, 'days').format('L'));
                      }}
                      startIcon={<RequestIcon />}
                      sx={{
                        background: accent800,
                        color: grey[50],
                        '&:hover': {
                          background: accent900,
                        },
                      }}
                    >
                      Request Extra Pick-Up
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      mt: `15px`,
                      background: grey[50],
                      borderRadius: `5px`,
                      boxShadow: `0 1px 2px ${grey[900]}`,
                    }}
                  >
                    <Box
                      sx={{
                        p: isDesktop ? `30px` : `15px`,
                        gap: `15px`,
                        display: `flex`,
                        flexDirection: `column`,
                      }}
                    >
                      {wastedgeServices &&
                        wastedgeServices.map((wastedgeService) => {
                          if (
                            wastedgeService.waste_type === 'FUT' ||
                            wastedgeService.waste_type === 'FUT1' ||
                            wastedgeService.waste_type === 'FUT2' ||
                            wastedgeService.waste_type === 'FUT3' ||
                            wastedgeService.waste_type === 'FUT4' ||
                            wastedgeService.waste_type === 'EXCESS'
                          ) {
                            return null;
                          } else {
                            return (
                              <Box
                                key={wastedgeService.$id}
                                sx={{
                                  color: accent900,
                                  gap: `15px`,
                                  display: `flex`,
                                  alignItems: `center`,
                                }}
                              >
                                <Box
                                  sx={{
                                    width: `48px`,
                                    height: `48px`,
                                    background: `repeating-linear-gradient(45deg, ${WasteTypes(wastedgeService.waste_type)
                                      .primaryColor
                                      } 0px, ${WasteTypes(wastedgeService.waste_type)
                                        .primaryColor
                                      } 15px, ${WasteTypes(wastedgeService.waste_type)
                                        .secondaryColor
                                      } 15px, ${WasteTypes(wastedgeService.waste_type)
                                        .secondaryColor
                                      } 30px)`,
                                    borderRadius: `5px`,
                                  }}
                                />
                                <Box>
                                  <Typography variant="h6">
                                    {
                                      WasteTypes(wastedgeService.waste_type)
                                        .name
                                    }
                                  </Typography>

                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                    }}
                                  >
                                    {wastedgeSchedules &&
                                      wastedgeSchedules.map(
                                        (wastedgeSchedule) => {
                                          if (
                                            wastedgeSchedule.service ===
                                            wastedgeService.$id
                                          ) {
                                            return (
                                              <Typography
                                                key={`schedule-${wastedgeSchedule.service}`}
                                                variant="caption"
                                                sx={{ color: grey[700] }}
                                              >
                                                Next pick-up in&nbsp;
                                                {moment(
                                                  wastedgeSchedule.next_service_date
                                                ).format('LL')}
                                                &nbsp;(
                                                {moment(
                                                  `${wastedgeSchedule.next_service_date
                                                  }-${moment().format(
                                                    'HH-mm-ss'
                                                  )}`,
                                                  'YYYY-MM-DD-HH:mm:ss'
                                                ).fromNow()}
                                                )
                                              </Typography>
                                            );
                                          }
                                        }
                                      )}
                                    {wastedgeService.route_cycle === 'RI' && (
                                      <Typography
                                        variant="caption"
                                        sx={{ color: grey[700] }}
                                      >
                                        Ring-in service
                                      </Typography>
                                    )}
                                  </Box>
                                </Box>
                              </Box>
                            );
                          }
                        })}
                    </Box>
                  </Box>
                </Box>
              )}
            </Container>
          </Box>
        )}
      </Box>
      {isDesktop ? <Footer /> : null}
      {wastedgeServices && wastedgeSchedules && (
        <ServiceModal
          modal={modalType}
          reqSchedule={reqSchedule}
          reqState={reqState}
          setReqState={setReqState}
          details={wastedgeDetails}
          services={wastedgeServices}
          schedules={wastedgeSchedules}
        />
      )}
    </Box>
  );
}
