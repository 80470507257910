import { lightBlue, red } from '@mui/material/colors';

export const accent800 = red['A700'];
export const accent900 = red[900];
export const primary500 = lightBlue[500];
export const primary900 = lightBlue[900];
export const secondary500 = '#002645';
export const secondary900 = '#021f36';
export const background500 = '#086175';
export const background600 = '#055b6e';
export const background700 = '#097f99';
export const background800 = '#056f87';
